import { useCallback } from 'react';
import { useLoadViewerSeries } from '~/hooks';
import { SetLayoutFunction } from './useLayoutSelector';
import { useErrorHandler } from '~/utils/appHelpers';
import { HangingProtocol } from '~/utils/hangingProtocol/hangingProtocolTypes';

/**
 * Delay to load the series after applying the layout change.
 */
const LOAD_SERIES_DELAY = 100;

/**
 * Custom hook to set the hanging protocol and load the corresponding series in a viewer.
 *
 * This hook provides a `setHangingProtocol` function that applies a specified hanging protocol
 * to a viewer layout and loads the associated series into their respective viewports.
 *
 * @returns {Function}
 *   A `setHangingProtocol` function that applies a hanging protocol.
 *
 * `setHangingProtocol` Parameters:
 * @param {HangingProtocol} protocol -
 *   The hanging protocol to apply, containing layout and series location information.
 *   - `layout` (any): The desired layout configuration for the viewer.
 *   - `serieLocations` (Array): An array of objects specifying the series and their positions:
 *     - `serie` (object): The series to load, with an optional `id` property.
 *     - `viewportPositionIndex` (number): The index of the viewport where the series should be displayed.
 *     - `viewportImageIndex` (number): The index of the image to display in the viewport.
 * @param {Function} setLayoutFunction -
 *   A function to update the viewer layout. It should accept the layout configuration,
 *   along with flags for reset and force parameters.
 *
 * Usage:
 * 1. Sets the viewer layout according to the protocol.
 * 2. Loads the specified series into the appropriate viewports.
 * 3. The series loading is deferred using `setTimeout` to ensure layout changes are applied first.
 *
 * @example
 * const setHangingProtocol = useSetHangingProtocol();
 *
 * const protocol = {
 *   layout: { rows: 2, columns: 2 },
 *   serieLocations: [
 *     { serie: { id: 'serie1' }, viewportPositionIndex: 0, viewportImageIndex: 0 },
 *     { serie: { id: 'serie2' }, viewportPositionIndex: 1, viewportImageIndex: 0 },
 *   ],
 * };
 *
 * setHangingProtocol(protocol, updateLayoutFunction);
 */

export const useSetHangingProtocol = () => {
  const { handleError } = useErrorHandler();

  const { loadAndSetCurrentSerieById } = useLoadViewerSeries({
    onError: (error) => handleError({ logMessage: error }),
  });

  const setHangingProtocol = useCallback((protocol: HangingProtocol, setLayoutFunction: SetLayoutFunction) => {
    const { serieLocations, layout } = protocol;
    const onlyLocalLayoutChange = true; // Prevent broadcast the layout change to other windows
    const onlySetLayout = true; // Only set the layout, prevent load serie info automatically
    setLayoutFunction(layout, onlyLocalLayoutChange, onlySetLayout);
    setTimeout(() => {
      serieLocations.forEach(({ serie, viewportPositionIndex, viewportImageIndex }) => {
        if (!serie?.id) return;
        loadAndSetCurrentSerieById({
          serieId: serie.id,
          imageIndex: viewportImageIndex,
          viewportIndex: viewportPositionIndex,
        });
      });
    }, LOAD_SERIES_DELAY);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return setHangingProtocol;
};

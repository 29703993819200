import cornerstone from 'cornerstone-core';
import csTools from 'cornerstone-tools';

const getNewContext = csTools.importInternal('drawing/getNewContext');
const { magnifyCursor } = csTools.importInternal('tools/cursors');
const BaseTool = csTools.importInternal('base/BaseTool');
const debounce = csTools.importInternal('util/debounce');

import { i18n } from '@eva-pacs/i18n';

import { shiftKeyIllustration, controlKeyIllustration, mouseControlIllustration } from '~/src/illustrations';
import { useViewportStore } from '~/src/store';

import { hideToolCursor, setToolCursor } from './helpers/setToolCursor';

import {
  destroyMagnificationCanvas,
  disableZoomIndicator,
  getMagnifyCanvas,
  getMagnifyContainer,
  CORNERSTONE_SELECTOR,
  CONTAINER_CLASS,
  MAGNIFY_CLASS,
  HINT_CLASS,
  ZOOM_CLASS,
  getZoomIndicator,
} from './helpers/magnifyToolHelpers';

const MAGNIFY_MIN_LIMIT = 32;
const ZOOM_MIN_LIMIT = 1.1;
const ZOOM_MAX_LIMIT = 5.0;

/**
 * @public
 * @class MagnifyTool
 * @memberof Tools
 *
 * @classdesc Tool for inspecting a region at increased magnification.
 * @extends Tools.Base.BaseTool
 */
export default class MagnifyTool extends BaseTool {
  debouncedZoomIndicator: any;
  zoomCanvas: any;
  zoomElement: any;
  activeCallback: (element: any) => void;
  enabledCallback: (element: any) => void;
  disabledCallback: (element: any) => void;
  postTouchStartCallback: (event: any) => void;
  touchDragCallback: (event: any) => void;
  touchEndCallback: (event: any) => void;
  touchDragEndCallback: (event: any) => void;
  postMouseDownCallback: (event: any) => void;
  mouseDragCallback: (event: any) => void;
  mouseUpCallback: (event: any) => void;
  mouseClickCallback: (event: any) => void;
  newImageCallback: (event: any) => void;
  boundToolChanges: (event: any) => void;

  configuration: any;
  dragY?: number;
  zoomTimer?: Date;
  currentCursorPoint?: {
    x: number;
    y: number;
  };

  constructor(props = {}) {
    const defaultProps = {
      name: 'Magnify',
      supportedInteractionTypes: ['Mouse', 'Touch'],
      configuration: {
        magnifySize: 300,
        magnificationLevel: 2,
        showHint: true,
      },
      svgCursor: magnifyCursor,
    };

    super(props, defaultProps);

    this.zoomElement = undefined;
    this.zoomCanvas = undefined;
    this.zoomTimer = undefined;
    this.dragY = undefined;

    this.currentCursorPoint = {
      x: 0,
      y: 0,
    };

    // Mode Callbacks: (element, options)
    this.activeCallback = this._createMagnificationCanvas.bind(this);
    this.enabledCallback = this._createMagnificationCanvas.bind(this);
    this.disabledCallback = destroyMagnificationCanvas.bind(this);

    // Touch
    this.postTouchStartCallback = this._addMagnifyingGlass.bind(this);
    this.touchDragCallback = this._updateMagnifyingGlass.bind(this);
    this.touchEndCallback = this._removeMagnifyingGlass.bind(this);
    this.touchDragEndCallback = this._removeMagnifyingGlass.bind(this);
    // Mouse
    this.postMouseDownCallback = this._addMagnifyingGlass.bind(this);
    this.mouseDragCallback = this._updateMagnifyingGlass.bind(this);
    this.mouseUpCallback = this._removeMagnifyingGlass.bind(this);
    // On quick clicks, mouseUp does not fire, but this does
    this.mouseClickCallback = this._removeMagnifyingGlass.bind(this);
    // Misc
    this.newImageCallback = this._drawMagnificationTool.bind(this);
    this.boundToolChanges = this._listenToolChanges.bind(this);
    this.debouncedZoomIndicator = debounce(disableZoomIndicator, 6000);
  }

  // Initializers

  private _addMagnifyingGlass(event) {
    // Ignore until next event
    this._removeZoomElement();
    this._drawZoomedElement(event);
    // On next frame
    window.requestAnimationFrame(() => this._drawMagnificationTool(event));

    hideToolCursor(event.detail.element);

    event.preventDefault();
    event.stopPropagation();
  }

  private _updateMagnifyingGlass(event) {
    event.detail.element.addEventListener('cornerstonetoolsmousedrag', this.boundToolChanges);
    this._drawMagnificationTool(event);

    event.preventDefault();
    event.stopPropagation();
  }

  element() {
    throw new Error('Method not implemented.');
  }

  svgCursor() {
    throw new Error('Method not implemented.');
  }

  // Functionality

  private _drawMagnificationTool(event) {
    const element = event.detail.element;
    const magnifyCanvas = getMagnifyCanvas(element);
    const magnifyContainer = getMagnifyContainer(element);

    if (!magnifyCanvas) this._createMagnificationCanvas(element);
    if (this.zoomCanvas === undefined) return;

    const containerIsPositioned = Boolean(magnifyContainer?.style?.top?.length);
    const isModifying = Boolean(this?.dragY) && containerIsPositioned;

    const cornerstoneCanvas = element.querySelector(CORNERSTONE_SELECTOR);
    const context = getNewContext(magnifyCanvas);

    const currentPoint = isModifying ? this.currentCursorPoint : event.detail.currentPoints.image;

    // Calculate the on-canvas location of the mouse pointer / touch
    const canvasLocation = cornerstone.pixelToCanvas(event.detail.element, currentPoint);

    const { canvasRawSize } = this._getSizes();
    const magnifySize = Math.min(canvasRawSize, cornerstoneCanvas.width, cornerstoneCanvas.height);
    const magnificationLevel = this.configuration.magnificationLevel;

    magnifyCanvas.width = magnifySize;
    magnifyCanvas.height = magnifySize;

    // Constrain drag movement to zoomed image boundaries
    canvasLocation.x = Math.max(canvasLocation.x, (0.5 * magnifySize) / magnificationLevel);
    canvasLocation.x = Math.min(canvasLocation.x, cornerstoneCanvas.width - (0.5 * magnifySize) / magnificationLevel);
    canvasLocation.y = Math.max(canvasLocation.y, (0.5 * magnifySize) / magnificationLevel);
    canvasLocation.y = Math.min(canvasLocation.y, cornerstoneCanvas.height - (0.5 * magnifySize) / magnificationLevel);

    const copyFrom = {
      x: canvasLocation.x * magnificationLevel - 0.5 * magnifySize,
      y: canvasLocation.y * magnificationLevel - 0.5 * magnifySize,
    };

    copyFrom.x = Math.max(copyFrom.x, 0);
    copyFrom.y = Math.max(copyFrom.y, 0);

    context.drawImage(
      this.zoomCanvas,
      copyFrom.x,
      copyFrom.y,
      magnifySize,
      magnifySize,
      0,
      0,
      magnifySize,
      magnifySize,
    );

    // Place the magnification tool at the same location as the pointer
    const touchOffset = event.detail.isTouchEvent ? 120 : 0;
    const magnifyPosition = {
      top: Math.max(canvasLocation.y - 0.5 * magnifySize - touchOffset, 0),
      left: Math.max(canvasLocation.x - 0.5 * magnifySize, 0),
    };

    // Get full magnifier dimensions with borders
    const magnifierBox = magnifyCanvas.getBoundingClientRect();

    // Constrain magnifier to canvas boundaries
    magnifyPosition.top = Math.min(magnifyPosition.top, cornerstoneCanvas.height - magnifierBox.height);
    magnifyPosition.left = Math.min(magnifyPosition.left, cornerstoneCanvas.width - magnifierBox.width);

    this._updateMagnificationCanvas(
      element,
      {
        top: `${magnifyPosition.top}px`,
        left: `${magnifyPosition.left}px`,
        display: 'block',
      },
      {
        display: 'block',
      },
    );
  }

  private _updateMagnificationCanvas(element, containerProperties = {}, canvasProperties = {}) {
    const magnifyCanvas = getMagnifyCanvas(element);
    const magnifyContainer = getMagnifyContainer(element);
    if (!magnifyCanvas || !magnifyContainer) return;

    Object.keys(containerProperties).forEach((key) => (magnifyContainer.style[key] = containerProperties[key]));
    Object.keys(canvasProperties).forEach((key) => (magnifyCanvas.style[key] = canvasProperties[key]));

    if ('height' in canvasProperties) magnifyCanvas.height = canvasProperties.height as number;
    if ('width' in canvasProperties) magnifyCanvas.width = canvasProperties.width as number;
  }

  private _drawZoomedElement(event) {
    const element = event.detail.element;
    let enabledElement = event.detail.enabledElement;

    if (enabledElement === undefined) enabledElement = cornerstone.getEnabledElement(element);
    if (!enabledElement) return;

    const magnificationLevel = this.configuration.magnificationLevel;
    const cornerstoneCanvas = enabledElement.canvas;
    const image = enabledElement.image;

    // Create a new cornerstone enabledElement
    if (!this.zoomElement) {
      this.zoomElement = document.createElement('div');
      this.zoomElement.width = cornerstoneCanvas.width * magnificationLevel;
      this.zoomElement.height = cornerstoneCanvas.height * magnificationLevel;
      cornerstone.enable(this.zoomElement, enabledElement.options);
    }

    const zoomEnabledElement = cornerstone.getEnabledElement(this.zoomElement);
    const viewport = cornerstone.getViewport(enabledElement.element);

    this.zoomCanvas = zoomEnabledElement.canvas;
    this.zoomCanvas.width = cornerstoneCanvas.width * magnificationLevel;
    this.zoomCanvas.height = cornerstoneCanvas.height * magnificationLevel;

    zoomEnabledElement.viewport = Object.assign({}, viewport);

    // Update it's viewport to render at desired magnification level
    // @ts-ignore
    viewport.scale *= magnificationLevel;
    cornerstone.displayImage(this.zoomElement, image);
    cornerstone.setViewport(this.zoomElement, viewport);
  }

  private _listenToolChanges(event) {
    const magnifyDelta = 4;
    const magnificationDelta = 0.2;

    const clientY = event.detail.currentPoints.client.y;
    const isZooming = event.detail.ctrlKey;
    const isIncreasingSize = event.detail.shiftKey;
    const element = event.detail.element;
    const isModifying = !isIncreasingSize && !isZooming;

    if (isModifying) return (this.dragY = undefined);

    let lastY = this.dragY as number;

    if (!lastY) {
      lastY = clientY;
      this.dragY = clientY;
      this.currentCursorPoint = event.detail.currentPoints.image;
    }

    const isMovingUp = clientY < lastY;
    const existChangeInY = clientY !== lastY;
    if (isIncreasingSize && existChangeInY) {
      this._disableHintComponent(element);
      disableZoomIndicator(element);
      const MAGNIFY_MAX_LIMIT = element.clientHeight * 0.8;
      const delta = isMovingUp
        ? this.configuration.magnifySize + magnifyDelta
        : this.configuration.magnifySize - magnifyDelta;
      if (delta < MAGNIFY_MIN_LIMIT || delta > MAGNIFY_MAX_LIMIT) return;

      this.configuration.magnifySize = delta;
    }

    if (isZooming && existChangeInY) {
      this._disableHintComponent(element);
      const delta = isMovingUp
        ? this.configuration.magnificationLevel + magnificationDelta
        : this.configuration.magnificationLevel - magnificationDelta;
      if (delta < ZOOM_MIN_LIMIT || delta > ZOOM_MAX_LIMIT) return;

      this.configuration.magnificationLevel = delta;
      this._drawZoomedElement(event);
      this._updateZoomIndicator(element);
      this.debouncedZoomIndicator(element);
    }
    this.dragY = clientY;
  }

  // Layout

  private _createMagnificationCanvas(element) {
    useViewportStore.getState().setDisableHotkeys();
    const magnifyCanvas = getMagnifyCanvas(element);
    if (magnifyCanvas) return;

    const { canvasRawSize } = this._getSizes();
    const showHint = this.configuration.showHint;

    const newMagnifyContainer = document.createElement('div');
    newMagnifyContainer.classList.add(
      CONTAINER_CLASS,
      'e-border',
      'e-border-primary-200',
      'e-rounded-lg',
      'e-z-10',
      'w-fit',
      'h-fit',
    );
    newMagnifyContainer.style.position = 'absolute';
    newMagnifyContainer.style.display = 'block';

    const newMagnifyCanvas = document.createElement('canvas');
    newMagnifyCanvas.classList.add(MAGNIFY_CLASS, showHint ? 'e-rounded-t-lg' : 'e-rounded-lg', 'e-transition-all');
    newMagnifyCanvas.width = canvasRawSize;
    newMagnifyCanvas.height = canvasRawSize;
    newMagnifyCanvas.style.display = 'block';
    newMagnifyCanvas.style.transitionDuration = '30ms';

    newMagnifyContainer.appendChild(newMagnifyCanvas);

    if (showHint) {
      const hintContainer = this._createAndGetHintComponent();
      const zoomIndicator = this._createAndGetZoomIndicator();
      newMagnifyContainer.appendChild(hintContainer);
      newMagnifyContainer.appendChild(zoomIndicator);
    }

    element.appendChild(newMagnifyContainer);
    this._disableHintsProgrammatically(element);
  }

  private _createAndGetHintComponent() {
    const { canvasRawSize } = this._getSizes();
    const hintContainer = document.createElement('div');
    hintContainer.classList.add(
      'e-flex',
      'e-justify-center',
      'e-items-center',
      'e-bg-neutral-700',
      'e-rounded-b-lg',
      HINT_CLASS,
    );
    hintContainer.style.width = `${canvasRawSize}px`;

    const examplesContainer = document.createElement('div');
    examplesContainer.classList.add(
      'e-flex',
      'e-justify-center',
      'e-items-center',
      'e-bg-neutral-800',
      'e-w-40',
      'e-gap-2',
      'e-rounded-b-lg',
    );

    const exampleContainer = document.createElement('div');
    exampleContainer.classList.add('e-flex', 'e-justify-center', 'e-items-center');

    const exampleDivider = document.createElement('div');
    exampleDivider.classList.add('e-h-4', 'e-w-px', 'e-bg-neutral-500');

    const secondExampleContainer = document.createElement('div');
    secondExampleContainer.classList.add('e-flex', 'e-justify-center', 'e-items-center');

    const controlIllustration = document.createElement('img');
    controlIllustration.classList.add('e-pt-2');
    controlIllustration.src = controlKeyIllustration;

    const altIllustration = document.createElement('img');
    altIllustration.classList.add('e-pt-2');
    altIllustration.src = shiftKeyIllustration;

    const plusText = document.createElement('span');
    plusText.classList.add('e-text-base-white', 'e-text-2xs', 'e-pr-1');
    plusText.textContent = '+';

    const secondPlusText = document.createElement('span');
    secondPlusText.classList.add('e-text-base-white', 'e-text-2xs', 'e-pr-1');
    secondPlusText.textContent = '+';

    const mouseIllustration = document.createElement('img');
    mouseIllustration.src = mouseControlIllustration;

    const secondMouseIllustration = document.createElement('img');
    secondMouseIllustration.src = mouseControlIllustration;

    const hintText = document.createElement('p');
    hintText.classList.add('e-text-base-white', 'e-text-2xs', 'e-p-2', 'e-flex-1');
    hintText.textContent = i18n.t('study.magnifyTool');

    exampleContainer.appendChild(controlIllustration);
    exampleContainer.appendChild(plusText);
    exampleContainer.appendChild(mouseIllustration);

    secondExampleContainer.appendChild(altIllustration);
    secondExampleContainer.appendChild(secondPlusText);
    secondExampleContainer.appendChild(secondMouseIllustration);

    examplesContainer.appendChild(exampleContainer);
    examplesContainer.appendChild(exampleDivider);
    examplesContainer.appendChild(secondExampleContainer);

    hintContainer.appendChild(examplesContainer);
    hintContainer.appendChild(hintText);

    return hintContainer;
  }

  private _createAndGetZoomIndicator() {
    const zoomIndicator = document.createElement('span');
    const bottomDistance = this.configuration.showHint ? 'e-bottom-14' : 'e-bottom-3';

    zoomIndicator.classList.add(
      'e-text-2xs',
      'e-text-primary-200',
      'e-absolute',
      'e-right-3',
      'e-z-20',
      bottomDistance,
      ZOOM_CLASS,
    );
    zoomIndicator.textContent = `x${parseFloat(this.configuration.magnificationLevel).toFixed(1)}`;

    return zoomIndicator;
  }

  private _updateZoomIndicator(element) {
    const oldZoomIndicator = getZoomIndicator(element);
    if (oldZoomIndicator)
      return (oldZoomIndicator.textContent = `x${parseFloat(this.configuration.magnificationLevel).toFixed(1)}`);

    const newMagnifyContainer = getMagnifyContainer(element);
    const zoomIndicator = this._createAndGetZoomIndicator();
    newMagnifyContainer.appendChild(zoomIndicator);
  }

  // Getters

  private _getSizes(size = this.configuration.magnifySize) {
    return {
      canvasRawSize: size - 2,
    };
  }

  // Removers

  private _removeMagnifyingGlass(event) {
    const element = event.detail.element;

    element.removeEventListener('cornerstonetoolsmousedrag', this.boundToolChanges);
    this.dragY = undefined;
    this.currentCursorPoint = undefined;
    setToolCursor(this.element, this.svgCursor);

    this._updateMagnificationCanvas(element, { display: 'none', top: '', left: '' }, { display: 'none' });
    this._removeZoomElement();
  }

  private _removeZoomElement() {
    if (this.zoomElement !== undefined) {
      cornerstone.disable(this.zoomElement);
      this.zoomElement = undefined;
      this.zoomCanvas = undefined;
    }
  }

  private _disableHintComponent(element) {
    this.configuration.showHint = false;

    const component = element.querySelector(`.${HINT_CLASS}`);
    if (!component) return;
    component?.remove();

    this._updateMagnificationCanvas(element, {}, { borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px' });
  }

  private _disableHintsProgrammatically(element) {
    setTimeout(() => {
      this.configuration.showHint = false;
      this._disableHintComponent(element);
      disableZoomIndicator(element);
    }, 6000);
  }
}

/* eslint-disable i18next/no-literal-string */
import React, { useCallback, useRef, useState } from 'react';
import QRCode from 'qrcode.react';
import cn from 'classnames';

import { exportFile, FileExtension, svgToImage } from '@eva-pacs/core';
import {
  ButtonColor,
  ButtonVariant,
  Button,
  BasicInput,
  ClipboardCopyButton,
  IconCatalog,
  ButtonSize,
} from '@eva-pacs/ui';
import { useGetViewerQrService } from '~/hooks';
import { useTranslation } from 'react-i18next';
import { toPng } from 'html-to-image';

export interface PreviewQRModalProps {
  /**
   * Descriptive text to be read to screenreaders
   */
  ariaLabel?: string;

  /**
   * Use to generate the QR code
   */
  url?: string;

  patientPassword?: string;

  /**
   * Reference of Study Id
   */
  studyId: string;

  /**
   * Specify an optional className to be added to the component
   */
  className?: string;
}

/**
 * Use this component in order to show a QR code
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-09-03
 */
export const PreviewQRModal: React.FC<PreviewQRModalProps> = ({
  ariaLabel,
  studyId = '',
  url = '',
  patientPassword = '',
  className,
}) => {
  const { getViewerQrPdf } = useGetViewerQrService({ studyId });
  const nodeRef = useRef<HTMLDivElement>(null);
  const [copied, setCopied] = useState(false);
  const { t } = useTranslation();

  const classes = {
    qrContainer: cn('mb-2', {
      'e-flex e-flex-col e-p-2 e-items-center e-rounded-2xl e-bg-neutral-50 w-64': patientPassword,
    }),
    modalClass: cn(className),
  };

  const handleCopyBtnClick = () => setCopied(true);

  const handleCopied = (copied: boolean) => setCopied(copied);

  const handleDownloadPdf = () => getViewerQrPdf();

  const downloadSvg = (node: HTMLDivElement) => {
    const svg = node.innerHTML;
    svgToImage({
      svg,
      mimetype: 'image/png',
      quality: 1,
      outputFormat: 'blob',
    })
      .then((outputData) => {
        exportFile(outputData as any, 'qr-code.png', 'image/png');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onButtonClick = useCallback(() => {
    if (nodeRef.current === null) {
      return;
    }

    if (!patientPassword) {
      downloadSvg(nodeRef.current);
      return;
    }

    toPng(nodeRef.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = 'qr-code.png';
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [nodeRef, patientPassword]);

  if (!url)
    return (
      <div aria-label={ariaLabel} className="text-basic-white">
        NO QR
      </div>
    );

  return (
    <div aria-label={ariaLabel} className={classes.modalClass}>
      <div className="flex flex-col items-center justify-center justify-items-center">
        <div ref={nodeRef} className={classes.qrContainer}>
          <QRCode
            className="rounded-2xl"
            value={url}
            size={150}
            bgColor="#ffffff"
            fgColor="#000000"
            level="M"
            includeMargin={true}
            renderAs="svg"
          />

          {patientPassword && (
            <div className="e-flex e-flex-col e-text-center leading-4">
              <p className="e-mt-2 e-text-neutral-500 e-text-xs font-medium">{t('study.QRModal.scanQRDescription')}</p>

              <>
                <p className="e-mt-2 e-flex e-flex-col font-normal e-text-2xs w-60 leading-3">
                  {t('auth.formFields.password')}:
                </p>
                <p className="e-mt-1 e-text-xs e-text-neutral-500 e-text-sm font-bold leading-5">{patientPassword}</p>
              </>
            </div>
          )}
        </div>

        <div className="flex mb-6 mt-2">
          <Button
            onClick={onButtonClick}
            leftIcon={IconCatalog.download}
            size={ButtonSize.sm}
            variant={ButtonVariant.contained}
            color={ButtonColor.dark}>
            {FileExtension.PNG}
          </Button>
          <Button
            className="ml-3"
            size={ButtonSize.sm}
            onClick={handleDownloadPdf}
            leftIcon={IconCatalog.download}
            variant={ButtonVariant.contained}
            color={ButtonColor.dark}>
            {FileExtension.PDF}
          </Button>
        </div>
        <div className="flex items-center space-x-3">
          <BasicInput value={url} isReadOnly />
          <ClipboardCopyButton
            textToCopy={url}
            color={copied ? ButtonColor.secondaryAlternative : ButtonColor.primaryAlternative}
            isRounded={false}
            onCopyBtnClick={handleCopyBtnClick}
            onCopied={handleCopied}
          />
        </div>
      </div>
    </div>
  );
};

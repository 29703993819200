/* eslint-disable no-console */
import csTools from 'cornerstone-tools';
import merge from 'lodash.merge';

import { MIDDLE_MOUSE_BUTTON, RIGHT_MOUSE_BUTTON } from '~/constants';
import CTRTool from './cornestone/CTRTool';
import CoxometryRoiTool from './cornestone/CoxometryRoiTool';
import GoniometryRoiTool from './cornestone/GoniometryRoiTool';
import NippleInjuryTool from './cornestone/NippleInjuryTool/NippleInjuryTool';
import MagnifyTool from './cornestone/MagnifyTool';
import CustomAngleTool from './cornestone/CustomAngleTool';
import CustomArrowAnnotateTool from './cornestone/CustomArrowAnnotateTool';
import CustomCobbAngle from './cornestone/CustomCobbAngleTool';
import CustomEllipticalRoi from './cornestone/CustomEllipticalRoiTool';
import CustomLengthTool from './cornestone/CustomLengthTool';
import StackScrollMouseWheelTool from './cornestone/StackScrollMouseWheelTool';
import CustomProbeTool from './cornestone/CustomProbeTool';
import CustomRectangleRoi from './cornestone/CustomRectangleRoiTool';
import CustomRectangleCrop from './cornestone/CustomRectangleCrop';
import CustomDeviation from './cornestone/CustomDeviation';
import OverlayTool from './cornestone/OverlayTool';
import SpineLabelTool from './cornestone/SpineLabel/SpineLabelTool';
import ExternalReferenceLinesTool from './cornestone/ExternalReferenceLinesTool';
import MammographyReferenceLinesTool from './cornestone/MammographyReferenceLinesTool';
import SpineLabelReferencesTool from './cornestone/SpineLabel/SpineLabelReferences';
import { ToolName } from '../constants/toolName';
import CustomBidirectional from './cornestone/CustomBidirectionalTool';
import CustomFreehand from './cornestone/CustomFreehand';

interface Configuration {
  autoResizeViewports: boolean;
  globalToolSyncEnabled: boolean;
  showSVGCursors: boolean;
  hideHandles?: boolean;
  tools?: Array<void>;
}

interface ToolFunction extends VoidFunction {
  description: string;
  name: () => string;
}
export const annotationsTools = [
  CustomBidirectional,
  CustomFreehand,
  CTRTool,
  CoxometryRoiTool,
  GoniometryRoiTool,
  NippleInjuryTool,
  CustomAngleTool,
  CustomArrowAnnotateTool,
  CustomCobbAngle,
  CustomEllipticalRoi,
  CustomLengthTool,
  CustomProbeTool,
  CustomRectangleRoi,
  CustomRectangleCrop,
  CustomDeviation,
];

/*
 * When doing the build the name of some of these classes changes to
 * other more generic names and when it is disabled to hide the tools
 * the names do not match so this array is added that maintains the
 * original name of the tools
 */

export const annotationsToolsNames = [
  'CustomBidirectional',
  'CustomFreehandRoi',
  'CTRTool',
  'CoxometryTool',
  'GoniometryTool',
  'NippleInjuryTool',
  'CustomAngleTool',
  'CustomArrowAnnotateTool',
  'CustomCobbAngle',
  'CustomEllipticalRoi',
  'CustomLengthTool',
  'CustomProbeTool',
  'CustomRectangleRoi',
  'CustomRectangleCrop',
  'CustomDeviation',
];

/**
 * Handles all the cornerstone tools activation
 * @author Mario Lugo<mariolugo23@gmail.com>
 * Created at 2021-04-05
 */
export default function initCornerstoneTools(handleArrowAnnotationCallback) {
  const configuration: Configuration = {
    autoResizeViewports: false,
    globalToolSyncEnabled: true,
    showSVGCursors: true,
    hideHandles: false,
  };

  const toolsGroupedByType = {
    touch: [csTools.PanMultiTouchTool, csTools.ZoomTouchPinchTool],
    annotations: annotationsTools,
    other: [
      csTools.PanTool,
      csTools.ZoomTool,
      csTools.WwwcTool,
      csTools.WwwcRegionTool,
      MagnifyTool,
      csTools.StackScrollTool,
      StackScrollMouseWheelTool,
      OverlayTool,
      ExternalReferenceLinesTool,
      csTools.EraserTool,
      csTools.RotateTool,
      csTools.ReferenceLinesTool,
      MammographyReferenceLinesTool,
      SpineLabelTool,
      SpineLabelReferencesTool,
    ],
  };

  const tools: Array<ToolFunction> = [];
  Object.keys(toolsGroupedByType).forEach((toolsGroup) => tools.push(...toolsGroupedByType[toolsGroup]));

  /* Add extension tools configuration here. */
  const internalToolsConfig = {
    ArrowAnnotate: {
      configuration: {
        drawHandles: false,
        getTextCallback: (callback, event) => {
          return handleArrowAnnotationCallback(callback, event);
        },
        changeTextCallback: (data, eventDetails, callback) => {
          return console.log(data, eventDetails, callback);
        },
      },
    },
    Zoom: {
      configuration: {
        minScale: 0.1,
      },
    },
  };

  /* Abstract tools configuration using extension configuration. */
  const parseToolProps = (props, tool) => {
    const { annotations } = toolsGroupedByType;

    const toolsWithHideableHandles = annotations.filter(
      (tool) => !['RectangleRoiTool', 'EllipticalRoiTool'].includes(tool.name),
    );

    const parsedProps = { ...props };

    /**
     * drawHandles - Never/Always show handles
     * drawHandlesOnHover - Only show handles on handle hover (pointNearHandle)
     *
     * Does not apply to tools where handles aren't placed in predictable
     * locations.
     */
    if (configuration?.hideHandles !== false && toolsWithHideableHandles.includes(tool)) {
      if (props.configuration) {
        parsedProps.configuration.drawHandlesOnHover = true;
      } else {
        parsedProps.configuration = { drawHandlesOnHover: true };
      }
    }

    return parsedProps;
  };

  /* Add tools with its custom props through extension configuration. */
  tools.forEach((tool: ToolFunction) => {
    const toolName = new tool().name;
    const externalToolsConfig = configuration.tools || {};
    const externalToolProps = externalToolsConfig[toolName] || {};
    const internalToolProps = internalToolsConfig[toolName];

    const props = merge(internalToolProps, parseToolProps(externalToolProps, tool));
    csTools.addTool(tool, props);
  });

  /**
   * Set annotation tools to passive
   *
   * This is the default state for all tools.
   * Tools that are disabled cannot be intereacted with and are not rendered on the enabled element.
   *
   * https://tools.cornerstonejs.org/history/v2/essentials/tool-states.html
   */
  const BaseAnnotationTool = csTools.importInternal('base/BaseAnnotationTool');
  tools.forEach((tool: ToolFunction) => {
    if (tool.prototype instanceof BaseAnnotationTool) {
      const toolName = new tool().name;
      csTools.setToolPassive(toolName);
    }
  });

  /**
   * Set tool active with different button masks (secondary click, wheel click, etc..)
   */
  csTools.setToolActive('Pan', { mouseButtonMask: RIGHT_MOUSE_BUTTON });
  csTools.setToolActive('Zoom', { mouseButtonMask: MIDDLE_MOUSE_BUTTON });
  csTools.setToolActive('StackScrollMouseWheel', {});
  csTools.setToolActive('PanMultiTouch', { pointers: MIDDLE_MOUSE_BUTTON });
  csTools.setToolActive('ZoomTouchPinch', {});
  csTools.setToolActive('Overlay');
  csTools.setToolEnabled(ToolName.ExternalReferenceLines);
}

import { create, GetState, SetState } from 'zustand';
import produce from 'immer';

import { MeasurementType, StudyType } from '@eva-pacs/client';
import { MOVE, FileType } from '@eva-pacs/core';

import { hotKeysStore, HotkeysStore } from './hotkeys';
import { ConvolveFilter } from '~/constants';
import type { CriticalValue } from '../types/CriticalValue';

export const immer = (config) => (set, get) => config((fn) => set(produce(fn)), get);

/**
 * Study Store
 */
interface StudyStore {
  /**
   * Current study
   */
  study: StudyType | null;
  /**
   * If the viewer was dragged
   */
  viewerWasDragged: boolean;
  /**
   * Current study plugin. I don't think we are using this on state, consider refactor
   */
  plugin: string;
  /**
   * Critical values on state
   */
  criticalValues: Array<CriticalValue>;
  /**
   * Set study on state
   */
  setStudy: (study: StudyType) => void;
  /**
   * Set viewer was dragged on state
   */
  setViewerWasDragged: (value: boolean) => void;
  /**
   * Set plugin on state
   */
  setPlugin: (plugin: string) => void;
  /**
   * Resets the study when viewer is unmounted
   */
  resetStudy: () => void;
  /**
   * Set critical values on state
   */
  setCriticalValues: (criticalValues: Array<CriticalValue>) => void;
  /** Reset critical values on state
   */
  resetCriticalValues: () => void;
}

interface MeasurementInfo {
  /**
   * Measurements label
   */
  label: string;
  /**
   * Measurements description
   */
  description: string;
}

const studyStore = (set: SetState<StudyStore>) => ({
  study: null,
  criticalValues: [],
  viewerWasDragged: false,
  setStudy: (study) => {
    return set((state) => {
      state.study = study;
      return state;
    });
  },
  setViewerWasDragged: (value: boolean) => {
    return set((state) => {
      state.viewerWasDragged = value;
      return state;
    });
  },
  setPlugin: (plugin: string) => {
    return set((state) => {
      state.plugin = plugin;
      return state;
    });
  },
  resetStudy: () => {
    return set((state) => {
      state.study = null;
      return state;
    });
  },
  setCriticalValues: (criticalValues) => {
    return set((state) => {
      state.criticalValues = criticalValues;
      return state;
    });
  },
  resetCriticalValues: () => {
    return set((state) => {
      state.criticalValues = [];
      return state;
    });
  },
});

const useStudyStore = create<StudyStore>(immer(studyStore));

/**
 * Viewport Store
 */

export type Viewports = {
  /**
   * Current viewport plugin
   */
  plugin: string;
  /**
   * Current vtk plugin
   */
  vtk?: VTKProps;
};

interface VTKProps {
  /**
   * Vtk Mode
   */
  mode: string;
  /**
   * Vtk after creation handler, used when 2DViewer is done rendering and gets the VTK api
   */
  afterCreation: () => void;
}

export type Layout = {
  /**
   * Number of rows of the layout
   */
  numRows: number;
  /**
   * Number of column of the layout
   */
  numColumns: number;
  /**
   * Number of viewports of that layout
   */
  viewports: Viewports[];
  /**
   * Plugin of the layout
   */
  plugin?: string;
  /**
   * VTK hanlders
   */
  vtk?: VTKProps;
};

export type ViewportContent = {
  /**
   * Image load status
   */
  imageIsLoaded: boolean;
  /**
   * Last image Id loaded.
   */
  lastImageIdLoaded?: string;
  /**
   * Current HTML element used for cornerstone
   */
  dom?: HTMLElement;
  /**
   * Cinema playing state handler
   */
  isPlaying?: boolean;
  /**
   * Number of frames, used for cinema
   */
  frameRate?: number;
  /**
   * Id of the dicom study
   */
  dicomStudyId?: string;
  /**
   * Id of the series of this viewport
   */
  currentSerieId: string;
  /**
   * Current image index of the viewport
   */
  currentImageIndex: number;
  /**
   * Current window option of the viewport (Window option select on submenu)
   */
  currentWindow?: string;
  /**
   * Current window option of the viewport (Window option select on submenu)
   */
  displayType?: FileType;
  /**
   * Invert Selected State on submenu
   */
  invertSelected: boolean;
  /**
   * Flip Vertical Selected State on submenu
   */
  isFlipVSelected: boolean;
  /**
   * Flip Horizontal Selected State on submenu
   */
  isFlipHSelected: boolean;
  /**
   * If predefined level is adjusted
   */
  isLevelAdjusted: boolean;
  /**
   * If wwwc is adjusted
   */
  isWwwcAdjusted: boolean;
  /**
   * current convolve applied
   */
  currentConvolveFilter?: ConvolveFilter;
};

export type Cinema = {
  /**
   * Cinema playing handler
   */
  isPlaying: boolean;
  /**
   * Cinema frame rate
   */
  frameRate: number;
};

export type ViewportData = {
  [key: number]: ViewportContent;
};

/**
 * Global viewport data, contains all the viewports
 */
interface ViewportStore {
  /**
   * Active index of the viewport
   */
  activeIndex: number;
  /**
   * Active index of the viewport
   */
  hideViewportInformationText: boolean;
  /**
   * Is viewport available to call in the dom
   */
  isViewportAvailable: boolean;
  /**
   * Is viewport resizing
   */
  isViewportResizing: boolean;
  /**
   * Current Layout of the viewport
   */
  layout: Layout;
  /**
   * Last viewport previewed
   */
  viewportPreviewed: number | null;
  /**
   * Sets the last viewport previewed
   */
  setViewportPreviewed: (index: number | null) => void;
  /**
   * Object containing all the viewports
   */
  viewportData: ViewportData;
  /**
   * Handles if hotkeys are enabled or disabled
   */
  disableHotkeys: boolean;
  /**
   * Keeps track of the last selected tool with cursor
   */
  selectedCursorTool: string;
  /**
   * Tool selected on MEASUREMENT_SELECTED handler
   */
  selectedHandlerTool: string;
  /**
   * Sets if some viewport is resizing
   */
  setIsViewportResizing: (value: boolean) => void;
  /**
   * Sets current convolve filter viewport
   */
  setViewportCurrentConvolveFilter: (viewportIndex: number, convolveFilter?: ConvolveFilter) => void;
  /**
   * get current convolve filter viewport
   */
  getViewportCurrentConvolveFilter: () => ConvolveFilter | undefined;
  /**
   * get convolve filter from a viewport
   */
  getConvolveFilterByViewportIndex: (viewportIndex: number) => ConvolveFilter | undefined;
  /**
   * Sets selected cursor tool
   */
  setSelectedCursorTool: (tool: string) => void;
  /**
   * Sets viewport information text visibility
   */
  setViewportInformationText: (value: boolean) => void;
  /**
   * Sets selected cursor tool
   */
  setIsViewportAvailable: (value: boolean) => void;
  /**
   * Sets the layout selected on disposition.
   */
  setLayout: (
    layout: Layout,
    options?: { isReset?: boolean; cleanNewSeries?: boolean; resetViewports?: boolean },
  ) => void;
  /**
   * Sets the active selected index
   */
  setActiveIndex: (index: number) => void;
  /**
   * Sets the HTML element of the viewport
   */
  setViewportElement: (index: number, element: HTMLElement) => void;
  /**
   * Clear the HTML element of the viewport
   */
  clearViewportElement: (index: number) => void;
  /**
   * Sets the cinema config on the viewport
   */
  setViewportCinema: (index: number, cinema: Cinema) => void;
  /**
   * Sets frame rate to viewport selected
   */
  setFrameRate: (index: number, frameRate: number) => void;
  /**
   * Sets image index on a specific viewport
   */
  setViewportImageIndex: (viewportIndex: number, imageIndex: number) => void;
  /**
   * Sets current window (Window select on submenu) on a specific viewport
   */
  setViewportCurrentWindow: (viewportIndex: number, window: string) => void;
  /**
   * Sets current serie id on a specific viewport
   */
  setViewportCurrentSerieId: (viewportIndex: number, serieId: string) => void;
  /**
   * Sets serie id in and image index in viewport
   */
  setSerieIdAndImageIndexViewport: (viewportIndex: number, serieId: string, imageIndex: number) => void;
  /**
   * Sets Invert active state on a specific viewport
   */
  setViewportInvertSelected: (viewportIndex: number, selected: boolean) => void;
  /**
   * Sets Flip Vertical active state on a specific viewport
   */
  setViewportFlipVSelected: (viewportIndex: number, selected?: boolean) => void;
  /**
   * Sets Flip horizontal state on a specific viewport
   */
  setViewportFlipHSelected: (viewportIndex: number, selected?: boolean) => void;
  /**
   * Gets the enabled element
   */
  getActiveViewportContent: () => ViewportContent;
  /**
   * Get last valid selected tool with cursor
   */
  getSelectedCursorTool: () => string;
  /**
   * Get first viewport element
   */
  getFirstViewportElement: () => ViewportContent;
  /**
   * Toggles hotkeys enabled state
   */
  toggleHotkeys: () => void;
  /**
   * Function to disable hotkeys
   */
  setDisableHotkeys: () => void;
  /**
   * Function to active hotkeys
   */
  activeHotkeys: () => void;
  /**
   * Resets all the viewport once the viewer un unmounted
   */
  resetViewport: () => void;
  /**
   * Sets if viewport image is loaded.
   */
  setViewportImageIsLoaded: (viewportIndex: number, imageIsLoaded: boolean) => void;
  /**
   * Sets if viewport image is loaded.
   */
  setViewportDisplayType: (viewportIndex: number, displayType: FileType) => void;
  /**
   * Get tool selected on MEASUREMENT_SELECTED handler
   */
  getSelectedHandlerTool: () => string;
  /**
   * Set tool selected on MEASUREMENT_SELECTED handler
   */
  setSelectedHandlerTool: (tool: string) => void;
  /**
   * Set level adjusted in viewport
   */
  setIsLevelAdjustedViewport: (index: number, isLevelAdjusted: boolean) => void;
  /**
   * Set wwwc adjusted in viewport
   */
  setIsWwwcAdjustedViewport: (index: number, isWwwcAdjusted: boolean) => void;
  /**
   * Get if adjustment level should be skipped
   */
  skipLevelAdjustment: (viewportIndex: number) => boolean;
  /**
   * Return true if some viewport content is iframe
   */
  viewportContentHasIframe: () => boolean;
  onStartBarDragCallback: () => void;
  setOnStartBarDragCallback: (callback: () => void) => void;
  setViewportLastImageLoaded: (index: number, imageId: string) => void;
}

const initViewportContent: ViewportContent = {
  currentSerieId: '',
  imageIsLoaded: false,
  isPlaying: false,
  currentImageIndex: 0,
  currentWindow: 'none',
  invertSelected: false,
  isFlipVSelected: false,
  isFlipHSelected: false,
  isLevelAdjusted: false,
  isWwwcAdjusted: false,
  currentConvolveFilter: undefined,
};

const viewportStore = (set: SetState<ViewportStore>, get: GetState<ViewportStore>): ViewportStore => ({
  activeIndex: 0,
  hideViewportInformationText: false,
  isViewportAvailable: false,
  isViewportResizing: false,
  layout: {
    numRows: 1,
    numColumns: 1,
    viewports: [{ plugin: 'cornerstone' }],
  },
  viewportData: {
    0: { ...initViewportContent },
  },
  disableHotkeys: false,
  viewportPreviewed: null,
  setViewportPreviewed: (value: number | null) => {
    return set((state) => {
      state.viewportPreviewed = value;
      return state;
    });
  },
  selectedCursorTool: MOVE,
  selectedHandlerTool: '',
  setIsViewportResizing: (value) => {
    return set((state) => {
      state.isViewportResizing = value;
      return state;
    });
  },
  setViewportCurrentConvolveFilter: (index, currentConvolveFilter) => {
    if (!get().viewportData[index]) return;
    return set((state) => {
      state.viewportData[index].currentConvolveFilter = currentConvolveFilter;
      return state;
    });
  },
  getViewportCurrentConvolveFilter: () => {
    const viewportContent = get().getActiveViewportContent();
    return viewportContent.currentConvolveFilter;
  },
  getConvolveFilterByViewportIndex: (viewportIndex) => {
    const viewportContent = get().viewportData[viewportIndex];
    return viewportContent?.currentConvolveFilter;
  },
  onStartBarDragCallback: () => {},
  setOnStartBarDragCallback: (callback: VoidFunction) => {
    return set((state) => {
      state.onStartBarDragCallback = callback;
      return state;
    });
  },
  setLayout: (layout, options = { isReset: false, cleanNewSeries: false, resetViewports: false }) => {
    const { isReset, cleanNewSeries, resetViewports } = options;
    return set((state) => {
      state.layout = layout;
      const oldViewports = { ...state.viewportData };
      state.viewportData = {};
      const lastViewportIndex = Object.keys(oldViewports).length - 1;
      layout.viewports.forEach((viewport, index) => {
        const currentViewport = viewport.vtk
          ? oldViewports[get().activeIndex]
          : oldViewports[index] ?? oldViewports[lastViewportIndex];
        if ((!oldViewports[index] && cleanNewSeries) || resetViewports) {
          currentViewport.dom = undefined;
          currentViewport.currentSerieId = '';
          currentViewport.imageIsLoaded = false;
        }
        state.viewportData[index] = {
          ...currentViewport,
          currentConvolveFilter: oldViewports[index]?.currentConvolveFilter,
          dom: viewport.vtk ? undefined : currentViewport?.dom,
          isLevelAdjusted: isReset ? false : currentViewport?.isLevelAdjusted ?? false,
          isWwwcAdjusted: isReset ? false : currentViewport?.isWwwcAdjusted ?? false,
        };
      });
      state.activeIndex = 0;
      return state;
    });
  },
  getSelectedCursorTool: () => get().selectedCursorTool,
  getSelectedHandlerTool: () => get().selectedHandlerTool,
  setActiveIndex: (index) => {
    return set((state) => {
      state.activeIndex = index;
      return state;
    });
  },
  setViewportCinema: (index, { isPlaying = false, frameRate = 24 }) => {
    if (!get().viewportData[index]) return;
    return set((state) => {
      state.viewportData[index].frameRate = frameRate;
      state.viewportData[index].isPlaying = isPlaying;
      return state;
    });
  },
  setViewportLastImageLoaded: (index: number, imageId: string) => {
    if (!get().viewportData[index]) return;
    return set((state) => {
      state.viewportData[index].lastImageIdLoaded = imageId;
      return state;
    });
  },
  setViewportElement: (index, element) => {
    if (!get().viewportData[index]) return;
    return set((state) => {
      state.viewportData[index].dom = element;
      return state;
    });
  },
  clearViewportElement: (index) => {
    if (!get().viewportData[index]) return;
    return set((state) => {
      state.viewportData[index].dom = undefined;
      return state;
    });
  },
  setFrameRate: (index, frameRate) => {
    if (!get().viewportData[index]) return;
    return set((state) => {
      state.viewportData[index].frameRate = frameRate;
      return state;
    });
  },
  setViewportImageIsLoaded: (viewportIndex, imageIsLoaded) => {
    if (!get().viewportData[viewportIndex]) return;
    return set((state) => {
      state.viewportData[viewportIndex].imageIsLoaded = imageIsLoaded;
      return state;
    });
  },
  setViewportDisplayType: (viewportIndex, displayType) => {
    if (!get().viewportData[viewportIndex]) return;
    return set((state) => {
      state.viewportData[viewportIndex].displayType = displayType;
      return state;
    });
  },
  setViewportImageIndex: (viewportIndex, imageIndex) => {
    if (!get().viewportData[viewportIndex]) return;
    return set((state) => {
      state.viewportData[viewportIndex].currentImageIndex = imageIndex;
      return state;
    });
  },
  setViewportCurrentWindow: (viewportIndex, window) => {
    if (!get().viewportData[viewportIndex]) return;
    return set((state) => {
      state.viewportData[viewportIndex].currentWindow = window;
      return state;
    });
  },
  setViewportCurrentSerieId: (viewportIndex, serieId) => {
    if (!get().viewportData[viewportIndex]) return;
    return set((state) => {
      state.viewportData[viewportIndex].currentSerieId = serieId;
      return state;
    });
  },
  setSerieIdAndImageIndexViewport: (viewportIndex, serieId, imageIndex = 0) => {
    const currentViewport = get().viewportData[viewportIndex];
    if (!currentViewport) return;
    return set((state) => {
      state.viewportData[viewportIndex].currentSerieId = serieId;
      state.viewportData[viewportIndex].currentImageIndex = imageIndex;
      return state;
    });
  },
  setViewportInvertSelected: (viewportIndex, selected) => {
    if (!get().viewportData[viewportIndex]) return;
    return set((state) => {
      state.viewportData[viewportIndex].invertSelected = selected;
      return state;
    });
  },
  setViewportFlipVSelected: (viewportIndex, selected) => {
    if (!get().viewportData[viewportIndex]) return;
    return set((state) => {
      const isSelected =
        typeof selected === 'undefined' ? !state.viewportData[viewportIndex].isFlipVSelected : selected;
      state.viewportData[viewportIndex].isFlipVSelected = isSelected;
      return state;
    });
  },
  setViewportFlipHSelected: (viewportIndex, selected) => {
    if (!get().viewportData[viewportIndex]) return;
    return set((state) => {
      const isSelected =
        typeof selected === 'undefined' ? !state.viewportData[viewportIndex].isFlipHSelected : selected;
      state.viewportData[viewportIndex].isFlipHSelected = isSelected;
      return state;
    });
  },
  getActiveViewportContent: () => {
    const selectedIndex = get().activeIndex;
    const viewportData = get().viewportData;

    return viewportData[selectedIndex];
  },
  getFirstViewportElement: () => {
    const viewportData = get().viewportData;
    return viewportData[0];
  },
  setIsViewportAvailable: (value) => {
    return set((state) => {
      state.isViewportAvailable = value;
      return state;
    });
  },
  toggleHotkeys: () => {
    return set((state) => {
      state.disableHotkeys = !state.disableHotkeys;
      return state;
    });
  },
  setDisableHotkeys: () => {
    return set((state) => {
      state.disableHotkeys = true;
      return state;
    });
  },
  activeHotkeys: () => {
    return set((state) => {
      state.disableHotkeys = false;
      return state;
    });
  },
  resetViewport: () => {
    return set((state) => {
      state.activeIndex = 0;
      state.viewportData = {
        0: { ...initViewportContent },
      };
      state.layout = {
        numRows: 1,
        numColumns: 1,
        viewports: [{ plugin: 'cornerstone' }],
      };
      return state;
    });
  },
  setSelectedCursorTool: (tool) => {
    return set((state) => {
      state.selectedCursorTool = tool;
      return state;
    });
  },
  setViewportInformationText: (value) => {
    return set((state) => {
      state.hideViewportInformationText = value;
      return state;
    });
  },
  setSelectedHandlerTool: (tool: string) => {
    return set((state) => {
      state.selectedHandlerTool = tool;
      return state;
    });
  },
  setIsLevelAdjustedViewport: (viewportIndex: number, isLevelAdjusted: boolean) => {
    if (!get().viewportData[viewportIndex]) return;
    return set((state) => {
      state.viewportData[viewportIndex].isLevelAdjusted = isLevelAdjusted;
      return state;
    });
  },
  setIsWwwcAdjustedViewport: (viewportIndex: number, isWwwcAdjusted: boolean) => {
    if (!get().viewportData[viewportIndex]) return;
    return set((state) => {
      state.viewportData[viewportIndex].isWwwcAdjusted = isWwwcAdjusted;
      return state;
    });
  },
  skipLevelAdjustment: (viewportIndex: number) => {
    const isLevelAdjusted = get().viewportData[viewportIndex].isLevelAdjusted;
    const isWwwcAdjusted = get().viewportData[viewportIndex].isWwwcAdjusted;

    return isLevelAdjusted || isWwwcAdjusted;
  },
  viewportContentHasIframe: () => {
    const viewportArray = Object.values(get().viewportData);
    return viewportArray.some((viewport) => viewport.displayType === FileType.STL);
  },
});

const useViewportStore = create<ViewportStore>(immer(viewportStore));

export interface MeasurementIdentifier {
  imageId: string;
  toolName: string;
  uuid: string;
}

export interface MeasurementDataInfo {
  toolName: string;
  data: string;
}

export type MeasurementData = {
  [key: string]: MeasurementType;
};
interface MeasurementStore {
  measurementsLoaded: boolean;
  selectedMeasurement: MeasurementIdentifier | null;
  copiedMeasurementData: MeasurementDataInfo | null;
  /**
   * All the measurements saved as an object
   */
  measurements: MeasurementData;
  /**
   * Current annotation text.
   */
  annotationText: string;
  setSelectedMeasurement: (selectedMeasurement: MeasurementIdentifier | null) => void;
  setCopiedMeasurement: (copiedMeasurement: MeasurementDataInfo | null) => void;
  setMeasurementsLoaded: () => void;
  /**
   * Adds a measurement
   */
  addMeasurement: (measurement: MeasurementType) => void;
  /**
   * Sets the annotation text on state
   */
  setAnnotationText: (text: string) => void;
  /**
   * Get the mesurements state in real time
   */
  getCurrentMeasurementList: () => MeasurementData;
  /**
   * Get a measurement on state by uuid
   */
  getMeasurementByUuid: (uuid: string) => MeasurementType;
  /**
   * Get the measurement length used on the measurements button
   */
  getMeasurementsLength: () => number;
  /**
   * Removes a measurement by uuid
   */
  removeMeasurementById: (id: string) => void;
  /**
   * Gest a List of measurements by serieId
   */
  getMeasurementsBySerieId: (serieId: string) => Array<MeasurementType>;
  /**
   * Gest a List of all measurements
   */
  getAllMeasurements: () => Array<MeasurementType>;
  /**
   * Removes all measurements
   */
  removeAllMeasurements: () => void;
  /**
   * Update a measurement by uuid
   */
  updateMeasurement: (uuid: string, measurement: MeasurementType) => void;
  /**
   * Updates measurement information
   */
  updateMeasurementInfo: (uuid: string, data: MeasurementInfo) => void;
  /**
   * Update measurement id
   */
  updateMeasurementId: (oldId: string, newId: string) => void;
  /**
   * Clears the annotation text
   */
  clearAnnotationText: () => void;
  /**
   * Resets all the measurement state on viewer unmount
   */
  resetMeasurements: () => void;
}

const measurementStore = (set: SetState<MeasurementStore>, get: GetState<MeasurementStore>): MeasurementStore => ({
  measurementsLoaded: false,
  selectedMeasurement: null,
  copiedMeasurementData: null,
  measurements: {},
  annotationText: '',
  setMeasurementsLoaded: () => {
    return set((state) => {
      state.measurementsLoaded = true;
      return state;
    });
  },
  addMeasurement: (measurement) => {
    return set((state) => {
      state.measurements = {
        ...state.measurements,
        [measurement.id]: { ...measurement },
      };
      return state;
    });
  },
  setAnnotationText: (text: string) => {
    return set((state) => {
      state.annotationText = text;
      return state;
    });
  },
  setSelectedMeasurement: (selectedMeasurement) => {
    return set((state) => {
      state.selectedMeasurement = selectedMeasurement;
      return state;
    });
  },
  setCopiedMeasurement: (copiedMeasurementData) => {
    return set((state) => {
      state.copiedMeasurementData = copiedMeasurementData;
      return state;
    });
  },
  getCurrentMeasurementList: () => get().measurements,
  getMeasurementByUuid: (uuid: string) => {
    const measurement = get().measurements[uuid];

    return measurement;
  },
  getMeasurementsBySerieId: (serieId: string) => {
    const measurements = get().measurements;
    const seriesMeasurements = Object.values(measurements).filter((measurement) => measurement.series.id === serieId);

    return seriesMeasurements;
  },
  getAllMeasurements: () => {
    const measurements = get().measurements;

    return Object.values(measurements);
  },
  getMeasurementsLength: () => {
    const measurement = get().measurements;

    return Object.keys(measurement).length;
  },
  removeMeasurementById: (id: string) => {
    return set((state) => {
      delete state.measurements[id];
      return state;
    });
  },
  removeAllMeasurements: () => {
    return set((state) => {
      state.measurements = {};
      return state;
    });
  },
  updateMeasurement: (uuid: string, measurement: MeasurementType) => {
    return set((state) => {
      state.measurements[uuid] = {
        ...state.measurements[uuid],
        ...measurement,
      };
      return state;
    });
  },
  updateMeasurementInfo: (uuid: string | number, data: MeasurementInfo) => {
    return set((state) => {
      state.measurements[uuid] = {
        ...state.measurements[uuid],
        label: data.label,
        description: data.description,
      };
      return state;
    });
  },
  updateMeasurementId: (oldId: string, newId: string) => {
    return set((state) => {
      state.measurements[newId] = {
        ...state.measurements[oldId],
      };
      delete state.measurements[oldId];
      return state;
    });
  },
  clearAnnotationText: () => {
    return set((state) => {
      state.annotationText = '';
      return state;
    });
  },
  resetMeasurements: () => {
    return set((state) => {
      state.measurements = {};
      return state;
    });
  },
});

const useMeasurementStore = create<MeasurementStore>(immer(measurementStore));

/**
 * Hotkeys store
 */
const useHotkeysStore = create<HotkeysStore>(immer(hotKeysStore));

export { useMeasurementStore, useStudyStore, useViewportStore, useHotkeysStore };
export * from './viewerStore';
export * from './sessionStore';
export * from './printerStore';
export * from './ecgStore';
export * from './templatesStore';
export * from './studyListStore';
export * from './flagsStore';
export * from './thumbnailSeriesStore';

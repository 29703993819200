import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { useTranslation } from 'react-i18next';
import { useToast, ToastPropsV2, ToastVariant, ButtonVariant } from '@evacenter/eden';

import { GlobalError, useGlobalErrorStore } from '../store/useGlobalErrorStore';

export const useGlobalError = () => {
  const { t } = useTranslation();
  const { addToast, deleteToast } = useToast();
  const toastError = useGlobalErrorStore((state) => state.toastError, shallow);

  const handleSetToastError = (error: GlobalError) => {
    const globalErrorId = 'global-error';
    addToast({
      id: globalErrorId,
      variant: ToastVariant.error,
      title: t('general.globalErrorTitle'),
      subtitle: t(error),
      actions: [
        {
          label: t('general.gotIt'),
          variant: ButtonVariant.tertiaryNeutral,
          onActionClick: () => deleteToast(globalErrorId),
        },
      ],
    } as ToastPropsV2);
  };

  useEffect(() => {
    if (toastError) handleSetToastError(toastError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toastError]);
};

import { LevelCatalog } from '@eva-pacs/core';
import { shallow } from 'zustand/shallow';

import { fullDynamicWWWC, getImage } from '~/utils/cornestone/cornestone.helpers';
import { cornerstoneLevels } from '~/components/Viewer/Toolbar/cornerstoneLevels';
import { useViewportStore } from '~/src/store';
import { Voi } from '~/src/interfaces/vtk';

export const useWwwcLevels = () => {
  const [
    setViewportCurrentWindow,
    activeIndex,
    viewportData,
    setIsLevelAdjustedViewport,
    setIsWwwcAdjustedViewport,
  ] = useViewportStore(
    (store) => [
      store.setViewportCurrentWindow,
      store.activeIndex,
      store.viewportData,
      store.setIsLevelAdjustedViewport,
      store.setIsWwwcAdjustedViewport,
    ],
    shallow,
  );
  const activeViewportData = viewportData[activeIndex || 0];

  const changeCornerstoneLevel = (value: string | LevelCatalog) => {
    setViewportCurrentWindow(activeIndex, value);
    const image = getImage(activeViewportData.dom);

    if (!image) return;
    const fullDynamicVOI: Voi | undefined = fullDynamicWWWC(image);

    cornerstoneLevels(value as LevelCatalog, activeViewportData.dom, fullDynamicVOI);
    const isLevelAdjusted = value !== LevelCatalog.NONE;
    setIsLevelAdjustedViewport(activeIndex, isLevelAdjusted);
    setIsWwwcAdjustedViewport(activeIndex, false);
  };

  return { changeCornerstoneLevel };
};

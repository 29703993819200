import { useDeleteMeasurementMutation } from '@eva-pacs/client';
import { shallow } from 'zustand/shallow';

import { handleRemoveMeasurement } from '~/components/Viewer/MeasurementsBrowser/handleMeasurements';
import { MeasurementIdentifier, useMeasurementStore, useSessionStore } from '~/src/store';
import type { MeasurementRemovalReason } from '~/src/constants/measurementRemovalReason';
import { getMeasurementIdByUUID } from '~/utils/measurements/getMeasurementData';
import { CAN_EDIT_MEASUREMENT, POLICIES } from '~/constants';

/**
 * Hookeable measuraments functions to use outside measurement browser and avoid prop drilling
 */
export const useMeasurementsHook = () => {
  const [getCurrentMeasurementList, removeMeasurementById] = useMeasurementStore(
    (store) => [store.getCurrentMeasurementList, store.removeMeasurementById],
    shallow,
  );
  const user = useSessionStore((store) => store.user);
  const currentMeasurements = getCurrentMeasurementList();

  const [deleteMeasurementById] = useDeleteMeasurementMutation();

  /**
   * Hook to remove measurement by Uuid on the data object on API and on global state
   */
  const handleRemoveMeasurementByUuid = async (
    measurement: MeasurementIdentifier,
    element: HTMLElement,
    deletedReason: MeasurementRemovalReason,
  ) => {
    const canUpdateMeasurement = POLICIES[CAN_EDIT_MEASUREMENT](user);
    if (!canUpdateMeasurement) return;

    const measurementId = getMeasurementIdByUUID(currentMeasurements, measurement.uuid);
    if (!measurementId) return;

    deleteMeasurementById({ variables: { id: measurementId, deletedReason } })
      .then(({ data }) => {
        if (!data?.deleteMeasurement?.success) return;
        handleRemoveMeasurement(
          measurementId,
          { tool: measurement.toolName, data: { uuid: measurement.uuid } },
          { dom: element },
          removeMeasurementById,
        );
      })
      .catch((error) => console.error(error));
  };

  return { handleRemoveMeasurementByUuid };
};

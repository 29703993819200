import { shallow } from 'zustand/shallow';
import { SerieItem, useSeriesStore, useViewportStore } from '~/src/store';

const DEFAULT_VIEWPORT_INDEX = 0;

export const useKeySeries = (viewportIndex = DEFAULT_VIEWPORT_INDEX) => {
  const [getSeries, getSerieById] = useSeriesStore((store) => [store.getSeries, store.getSerieById], shallow);
  const viewportData = useViewportStore((store) => store.viewportData);

  const serieId = viewportData[viewportIndex].currentSerieId;
  const imageIndex = viewportData[viewportIndex].currentImageIndex;

  const { isKeyImage, keySerie, totalKeyImages, isKeySerie } = getKeyInformation(
    getSeries(),
    getSerieById,
    serieId,
    imageIndex,
  );
  return { isKeyImage, keySerie, totalKeyImages: totalKeyImages || 0, isKeySerie };
};

const getKeyInformation = (
  series: SerieItem[],
  getSerieById: (serieId: string) => SerieItem | undefined,
  serieId: string,
  imageIndex: number,
) => {
  const keySerieIndex = series.findIndex((serie) => serie.isKeySeries);
  if (keySerieIndex === -1) return { isKeyImage: false, keySerie: {}, isKeySerie: false };

  const actualSerie = getSerieById(serieId);
  const keySerie = series[keySerieIndex];
  const isKeySerie = keySerie.id === serieId;

  if (!actualSerie?.dicomImageList?.length) return { isKeyImage: false, keySerie, isKeySerie };

  const actualImageId = actualSerie.dicomImageList[imageIndex]?.id;
  const isKeyImage = keySerie.dicomImageList.some((serie) => serie.id === actualImageId);

  return { isKeyImage, keySerie, totalKeyImages: keySerie?.dicomImageList?.length || 0, isKeySerie };
};

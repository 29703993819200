import csTools from 'cornerstone-tools';

const { modules, state } = csTools.store;

const globalConfiguration = modules.globalConfiguration;

/**
 * Creates an SVG Cursor for the target element
 *
 * @param {HTMLElement} element - The DOM Element to draw on
 * @param {MouseCursor} svgCursor - The cursor.
 * @returns {void}
 */
export const setToolCursor = (element, svgCursor) => {
  if (!globalConfiguration.configuration.showSVGCursors) return;
  const cursorBlob = svgCursor.getIconWithPointerSVG();
  const mousePoint = svgCursor.mousePoint;

  const svgCursorUrl = window.URL.createObjectURL(cursorBlob);
  element.style.cursor = `url('${svgCursorUrl}') ${mousePoint}, auto`;
  state.svgCursorUrl = svgCursorUrl;
};

export const resetToolCursor = (element) => _clearStateAndSetCursor(element, 'initial');

export const hideToolCursor = (element) => {
  if (!globalConfiguration.configuration.showSVGCursors) return;

  _clearStateAndSetCursor(element, 'none');
};

export const _clearStateAndSetCursor = (element, cursorSetting) => {
  if (state.svgCursorUrl) window.URL.revokeObjectURL(state.svgCursorUrl);

  state.svgCursorUrl = null;
  element.style.cursor = cursorSetting;
};

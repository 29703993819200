import { useCallback } from 'react';
import { shallow } from 'zustand/shallow';
import { useUpdateSerieMutation, UpdateSerieMutation } from '@eva-pacs/client';

import { DicomImageData, SerieItem, SeriesDicomImageData, useSeriesStore, useViewportStore } from '~/src/store';
import { useErrorHandler } from '~/utils/appHelpers';
import { FRAME_RATE } from '~/constants';
import { MetaSerieData } from '~/utils/thumbnails';

type SerieInitialData = {
  seriesInitialDataList: Array<
    Pick<SerieItem, 'id'> & Partial<Pick<SerieItem, 'numImageFrames'>> & { modalityType?: string; meta?: MetaSerieData }
  >;
};

export interface ViewerService {
  initViewerServiceData: () => void;
  getActiveViewportSerieData: () => SerieItem | undefined;
  getCurrentSeries: () => Array<SerieItem>;
  getFrameRateFromCurrentSerie: () => number;
  getSerieById: (serieId: string) => SerieItem;
  getViewportSerieData: (viewportIndex: number) => SerieItem | undefined;
  setCurrentSerieByIndex: (serieIndex: number) => void;
  addToSeriesList: ({ seriesInitialDataList }: SerieInitialData) => void;
  setDefaultFrameRate: () => void;
  setDefaultFrameRateBySerieId: (serieId: string, frameRate: number) => void;
  setDicomImageDataBySerieId: (dicomSerieId: string, dicomImageData: DicomImageData) => void;
  setSeriesDicomImageDataBySerieId: (dicomSerieId: string, seriesDicomImageData: SeriesDicomImageData) => void;
  saveCustomWwwc: (
    serieId: string,
    dicomWindowWidth: string,
    dicomWindowCenter: string,
  ) => Promise<UpdateSerieMutation | null | undefined>;
}

export const useViewerService = (): ViewerService => {
  // Hooks
  const { handleError } = useErrorHandler();
  const [getSeries, setSeries, setSerieData, setSelectedSerieIndex] = useSeriesStore(
    (store) => [store.getSeries, store.setSeries, store.setSerieData, store.setSelectedSerieIndex],
    shallow,
  );
  const [activeIndex, getActiveViewportContent, setFrameRate, viewportData] = useViewportStore(
    (store) => [store.activeIndex, store.getActiveViewportContent, store.setFrameRate, store.viewportData],
    shallow,
  );
  const [updateSerieMutation] = useUpdateSerieMutation({
    onError: (error) => handleError({ logMessage: error }),
  });

  // Service actions
  const initViewerServiceData = () => {
    setSeries([]);
    setSelectedSerieIndex(0);
  };

  const getCurrentSeries = (): Array<SerieItem> => getSeries();

  const getActiveViewportSerieData = useCallback((): SerieItem | undefined => {
    const currentSerieId = getActiveViewportContent()?.currentSerieId;
    return getSeries().find((serie) => serie.id === currentSerieId);
  }, [getActiveViewportContent, getSeries]);

  const getViewportSerieData = (viewportIndex: number): SerieItem | undefined => {
    const targetViewporData = viewportData[viewportIndex];
    return getSeries().find((serie) => serie.id === targetViewporData?.currentSerieId);
  };

  const getFrameRateFromCurrentSerie = (): number => getActiveViewportSerieData()?.frameRate || FRAME_RATE;

  const getSerieById = (serieId: string) => getSeries().find((serie) => serie.id === serieId) as SerieItem;

  const formatToSerieItemList = ({ seriesInitialDataList }: SerieInitialData) =>
    seriesInitialDataList.map(({ id, numImageFrames, modalityType, meta }) => {
      return ({
        id,
        isLoaded: false,
        modalityType,
        numImageFrames,
        meta,
      } as unknown) as SerieItem;
    });

  /** Add a new or update the list of series */
  const addToSeriesList = ({ seriesInitialDataList }: SerieInitialData) => {
    const initialSeriesList = formatToSerieItemList({ seriesInitialDataList });
    if (!initialSeriesList.length) return;

    const currentSeries = getSeries();
    const finalSeriesList = getUniqueSeriesList(initialSeriesList, currentSeries);
    setSeries(finalSeriesList);
  };

  /**
   * Compare the list of series to add with the current list of series
   */
  const getUniqueSeriesList = (compareList: Array<SerieItem>, auxiliarList: Array<SerieItem>) => {
    const auxiliarListIds = auxiliarList.map((serie) => serie.id);
    const uniqueSeriesList = compareList.filter((serie) => !auxiliarListIds.includes(serie.id));
    return [...auxiliarList, ...uniqueSeriesList];
  };

  const setCurrentSerieByIndex = (indexSerie: number): void => setSelectedSerieIndex(indexSerie);

  const setDefaultFrameRate = () => setFrameRate(activeIndex, getFrameRateFromCurrentSerie());

  const setDefaultFrameRateBySerieId = (dicomSerieId: string, frameRate: number) => {
    const imageSerieIndex = getCurrentSeries().findIndex((serie) => serie.dicomSeriesId === dicomSerieId);
    if (imageSerieIndex === -1) return;
    if (getCurrentSeries()[imageSerieIndex].frameRate === frameRate) return;
    const newSerieValue = { ...getCurrentSeries()[imageSerieIndex], frameRate };
    setSerieData(imageSerieIndex, newSerieValue);
    const currentdSerieId = getActiveViewportContent().currentSerieId;
    if (currentdSerieId === getCurrentSeries()[imageSerieIndex].id) setDefaultFrameRate();
  };

  const setSeriesDicomImageDataBySerieId = (dicomSeriesId: string, seriesDicomImageData: SeriesDicomImageData) => {
    const seriesIndex = getCurrentSeries().findIndex((series) => series.dicomSeriesId === dicomSeriesId);
    const seriesNotFound = seriesIndex === -1;
    if (seriesNotFound) return;

    const currentSeries = getCurrentSeries()[seriesIndex];
    const dicomImageData = Object.fromEntries(
      Object.entries(currentSeries).filter(([key]) => [...Object.keys(seriesDicomImageData)].includes(key)),
    );
    const hasDefinedValue = Object.values(dicomImageData).some((element) => element !== undefined);
    if (hasDefinedValue) return;

    const newSerieValue: SerieItem = {
      ...currentSeries,
      ...seriesDicomImageData,
    };
    setSerieData(seriesIndex, newSerieValue);
  };

  const setDicomImageDataBySerieId = (dicomSerieId: string, dicomImageData: DicomImageData) => {
    const serieIndex = getSeries().findIndex((serie) => serie.dicomSeriesId === dicomSerieId);
    if (serieIndex === -1) return;
    const newDicomImageList = [...getSeries()[serieIndex].dicomImageList];
    const imageDataIndex = newDicomImageList.findIndex((dicomImageItem) => dicomImageItem.id === dicomImageData.id);
    newDicomImageList[imageDataIndex] = { ...newDicomImageList[imageDataIndex], ...dicomImageData };
    const newSerieValue: SerieItem = { ...getCurrentSeries()[serieIndex], dicomImageList: newDicomImageList };
    setSerieData(serieIndex, newSerieValue);
  };

  const saveCustomWwwc = async (id: string, dicomWindowWidth: string, dicomWindowCenter: string) => {
    const { data } = await updateSerieMutation({
      variables: {
        id,
        input: {
          dicomWindowWidth,
          dicomWindowCenter,
        },
      },
    });
    return data;
  };

  // Export useViewerService values
  return {
    initViewerServiceData,
    getActiveViewportSerieData,
    getCurrentSeries,
    getFrameRateFromCurrentSerie,
    getSerieById,
    addToSeriesList,
    setCurrentSerieByIndex,
    setDefaultFrameRate,
    setDefaultFrameRateBySerieId,
    setDicomImageDataBySerieId,
    setSeriesDicomImageDataBySerieId,
    saveCustomWwwc,
    getViewportSerieData,
  };
};

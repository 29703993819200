import { create, GetState, SetState } from 'zustand';
import { produce } from 'immer';

import { isSmallWidth } from '@eva-pacs/core';
import type { MODALITY_TYPES } from '@eva-pacs/core';
import { FileType } from '@eva-pacs/core';
import { DEFAULT_VTK_PRESET_ID } from '~/constants';
import { MetaSerieData } from '~/utils/thumbnails';

import { ToolbarOption } from '../constants/toolbarOption';
import { VTKApi, Voi } from '../interfaces/vtk';

export const immer = (config) => (set, get) => config((fn) => set(produce(fn)), get);

export interface DicomImageData {
  externalId?: string;
  id: string;
}

export interface DicomWindowLevels {
  dicomWindowWidth?: string;
  dicomWindowCenter?: string;
}

export interface SerieItem {
  fileType?: FileType;
  id: string;
  description?: string;
  isLoaded: boolean;
  showResolutionAdjustedMessage?: boolean;
  usablePdfFile?: Blob;
  modalityType: MODALITY_TYPES;
  numImageFrames: number;
  dicomSeriesId: string;
  frameRate: number;
  dicomImageList: Array<DicomImageData>;
  imagesIds: Array<string>;
  largestImagePixelValue?: number;
  rescaleSlope?: string;
  smallestImagePixelValue?: number;
  rescaleIntercept?: string;
  isKeySeries?: boolean;
  showImageMap?: boolean;
  hasDocument: boolean;
  hasBookmarks?: boolean;
  meta?: MetaSerieData;
  studyId?: string;
}

export type SeriesDicomImageData = Pick<
  SerieItem,
  'largestImagePixelValue' | 'smallestImagePixelValue' | 'rescaleSlope' | 'rescaleIntercept'
>;

/**
 * Series Store
 */
interface SeriesState {
  series: Array<SerieItem>;
  selectedSerieIndex: number;
}

export interface SeriesStore extends SeriesState {
  getSelectedSeriesItem: () => SerieItem;
  getSeries: () => Array<SerieItem>;
  getLoadedSeries: () => Array<SerieItem>;
  getSerieById: (serieId: string) => SerieItem | undefined;
  getBookmarkSerie: () => SerieItem | undefined;
  getInstanceDataByImageUrl: (imageUrl: string) => { serieId: string; instanceId: string } | undefined;
  addNewSeries: (serie: SerieItem) => void;
  setSeries: (series: Array<SerieItem>) => void;
  setSelectedSerieIndex: (serieIndex: number) => void;
  setSerieData: (serieIndex: number, serie: SerieItem) => void;
  removeSerieById: (seieId: string) => void;
  setSerieDataBySerieId: (seieId: string, serieData: Partial<SerieItem>) => void;
}

const initialSeriesState: SeriesState = {
  series: [],
  selectedSerieIndex: 0,
};

const seriesStore = (set: SetState<SeriesStore>, get: GetState<SeriesStore>): SeriesStore => ({
  ...initialSeriesState,
  getSeries: () => get().series,
  getLoadedSeries: () => get().series.filter((serie) => serie.isLoaded),
  addNewSeries: (serie: SerieItem) => {
    return set((state) => {
      const actualSeries = [...get().series];
      actualSeries.push(serie);
      state.series = actualSeries;
      return state;
    });
  },
  getInstanceDataByImageUrl: (imageUrl: string) => {
    const availabelSeries = get().series.filter((serie) => serie.isLoaded);
    for (const serie of availabelSeries) {
      for (const image of serie.dicomImageList) {
        if (image.id === imageUrl) return { serieId: serie.id, instanceId: image.externalId as string };
      }
    }
  },
  getSerieById: (serieId: string) => get().series.find((serie) => serie.id === serieId),
  getBookmarkSerie: () => get().series.find((serie) => serie.hasBookmarks),
  getSelectedSeriesItem: () => {
    const selectedSerieIndex = get().selectedSerieIndex;
    return get().series[selectedSerieIndex];
  },
  setSeries: (series: Array<SerieItem>) => {
    return set((state) => {
      state.series = [...series];
      return state;
    });
  },
  removeSerieById: (serieId: string) => {
    return set((state) => {
      state.series = get().series.filter((serie) => serie.id !== serieId);
      return state;
    });
  },
  setSelectedSerieIndex: (serieIndex: number) => {
    return set((state) => {
      state.selectedSerieIndex = serieIndex;
      return state;
    });
  },
  setSerieDataBySerieId: (seieId: string, serieData: Partial<SerieItem>) => {
    const serieIndex = get().series.findIndex((serie) => serie.id === seieId);
    if (serieIndex === -1) return;
    return set((state) => {
      const seriesClone = [...state.series];
      Object.assign(seriesClone[serieIndex], serieData);
      state.series = [...seriesClone];
      return state;
    });
  },
  setSerieData: (serieIndex: number, serie: SerieItem) => {
    return set((state) => {
      const seriesClone = [...state.series];
      Object.assign(seriesClone[serieIndex], serie);
      state.series = [...seriesClone];
      return state;
    });
  },
});

export const useSeriesStore = create<SeriesStore>(immer(seriesStore));

/**
 * Panel Store
 */
export interface PanelStore {
  sequencePanelIsOpen: boolean;
  reportPanelIsOpen: boolean;
  measurementPanelIsOpen: boolean;
  setReportPanelIsOpen: (isOpen: boolean) => void;
  setSequencePanelIsOpen: (isOpen: boolean) => void;
  setMeasurementPanelIsOpen: (isOpen: boolean) => void;
}

const panelStore = (set: SetState<PanelStore>): PanelStore => ({
  sequencePanelIsOpen: !isSmallWidth(),
  reportPanelIsOpen: false,
  measurementPanelIsOpen: false,
  setReportPanelIsOpen: (isOpen: boolean) => {
    return set((state) => {
      state.reportPanelIsOpen = isOpen;
      return state;
    });
  },
  setSequencePanelIsOpen: (isOpen: boolean) => {
    return set((state) => {
      state.sequencePanelIsOpen = isOpen;
      return state;
    });
  },
  setMeasurementPanelIsOpen: (isOpen: boolean) => {
    return set((state) => {
      state.measurementPanelIsOpen = isOpen;
      return state;
    });
  },
});

export const usePanelStore = create<PanelStore>(immer(panelStore));

export type CropType = {
  serieId: string;
  areaData: {
    topLeftX: number;
    topLeftY: number;
    bottomRightX: number;
    bottomRightY: number;
  };
};

/**
 * Toolbar Store
 */
export interface ToolbarStore {
  toolbarParentOption: ToolbarOption;
  toolbarOptionSelected: ToolbarOption;
  mprSelected: boolean;
  threeDimensionalSelected: boolean;
  cropInfo?: CropType;
  setCropInfo: (cropData?: CropType) => void;
  setToolbarParentOption: (option: ToolbarOption) => void;
  setToolbarOptionSelected: (option: ToolbarOption) => void;
  setMprSelected: (isActive: boolean) => void;
  setThreeDimensionalSelected: (isActive: boolean) => void;
}

const toolbarStore = (set: SetState<ToolbarStore>): ToolbarStore => ({
  mprSelected: false,
  threeDimensionalSelected: false,
  cropInfo: undefined,
  toolbarParentOption: ToolbarOption.MOVE,
  toolbarOptionSelected: ToolbarOption.MOVE,
  setCropInfo: (cropData) => {
    return set((state) => {
      state.cropInfo = cropData;
      return state;
    });
  },
  setToolbarParentOption: (option: ToolbarOption) => {
    return set((state) => {
      state.toolbarParentOption = option;
      return state;
    });
  },
  setToolbarOptionSelected: (option: ToolbarOption) => {
    return set((state) => {
      state.toolbarOptionSelected = option;
      return state;
    });
  },
  setMprSelected: (isActive: boolean) => {
    return set((state) => {
      state.mprSelected = isActive;
      return state;
    });
  },
  setThreeDimensionalSelected: (isActive: boolean) => {
    return set((state) => {
      state.threeDimensionalSelected = isActive;
      return state;
    });
  },
});

export const useToolbarStore = create<ToolbarStore>(immer(toolbarStore));

/**
 * Vtk Store
 */
export interface VtkStore {
  vtkApis: Array<VTKApi>;
  defaultVOI: Voi;
  presetID: string;
  opacity3dLevel: number;
  isMeasureActive: boolean;
  onVtkCloseCallback: () => void;
  setVtkOnCloseCallback: (callback: VoidFunction) => void;
  setOpacity3dLevel: (opacity3dLevel: number) => void;
  setVtkApis: (vtkApis: Array<VTKApi>) => void;
  setDefaultVOI: (defaultVOI: Voi) => void;
  setPresetID: (presetID: VtkStore['presetID']) => void;
  setIfMeasureIsActive: (value: boolean) => void;
}

export const OPACITY_3D_VALUE = 2048;

const vtkStore = (set: SetState<VtkStore>): VtkStore => ({
  vtkApis: [] as Array<VTKApi>,
  defaultVOI: { windowCenter: 0, windowWidth: 0 },
  presetID: DEFAULT_VTK_PRESET_ID,
  opacity3dLevel: OPACITY_3D_VALUE,
  onVtkCloseCallback: () => {},
  setVtkOnCloseCallback: (callback: VoidFunction) => {
    return set((state) => {
      state.onVtkCloseCallback = callback;
      return state;
    });
  },
  isMeasureActive: false,
  setOpacity3dLevel: (opacity3dLevel: number) => {
    return set((state) => {
      state.opacity3dLevel = opacity3dLevel;
      return state;
    });
  },
  setVtkApis: (vtkApis: Array<VTKApi>) => {
    return set((state) => {
      state.vtkApis = [...vtkApis];
      return state;
    });
  },
  setDefaultVOI: (defaultVOI: Voi) => {
    return set((state) => {
      state.defaultVOI = { ...defaultVOI };
      return state;
    });
  },
  setPresetID: (presetID) => {
    return set((state) => {
      state.presetID = presetID;
      return state;
    });
  },
  setIfMeasureIsActive: (value) => {
    return set((state) => {
      state.isMeasureActive = value;
      return state;
    });
  },
});

export const useVtkStore = create<VtkStore>(immer(vtkStore));

/**
 * Additional Studies Store
 */
export interface AdditionalStudiesStore {
  studies: Array<string>;
  setStudies: (studies: Array<string>) => void;
  addStudy: (study: string) => void;
  removeStudy: (study: string) => void;
}

const additionalStudiesStore = (
  set: SetState<AdditionalStudiesStore>,
  get: GetState<AdditionalStudiesStore>,
): AdditionalStudiesStore => ({
  studies: [] as Array<string>,
  setStudies: (studies: Array<string>) => {
    return set((state) => {
      state.studies = studies;
      return state;
    });
  },
  addStudy: (study) => {
    // Permit only one instance of the study. Don't allow for repeated studies.
    const findStudy = Boolean(get().studies.filter((currentStudy) => currentStudy === study).length);
    if (findStudy) return;
    return set((state) => {
      state.studies.push(study);
      return state;
    });
  },
  removeStudy: (study) => {
    return set((state) => {
      const filteredStudies = state.studies.filter((currentStudy) => currentStudy !== study);
      state.studies = filteredStudies;
      return state;
    });
  },
});

export const useAdditionalStudiesStore = create<AdditionalStudiesStore>(immer(additionalStudiesStore));

/**
 * Match Series Studies
 */
export interface MatchSerieStudy {
  serieId: string;
  studyId: string;
}

/**
 * Match Series Studies Store
 */
export interface MatchSeriesStudiesStore {
  matchSeriesStudies: Array<MatchSerieStudy>;
  getStudyIdBySerieId: (serieId: string) => string | undefined;
  setMatchSeriesStudies: (studies: Array<MatchSerieStudy>) => void;
  addMatchSeriesStudies: (matchSeriesStudies: Array<MatchSerieStudy>) => void;
  removeMatchSerieStudy: (serieId: string) => void;
}

const matchSeriesStudiesStore = (
  set: SetState<MatchSeriesStudiesStore>,
  get: GetState<MatchSeriesStudiesStore>,
): MatchSeriesStudiesStore => ({
  matchSeriesStudies: [] as Array<MatchSerieStudy>,
  setMatchSeriesStudies: (matchSeriesStudies: Array<MatchSerieStudy>) => {
    return set((state) => {
      state.matchSeriesStudies = matchSeriesStudies;
      return state;
    });
  },
  getStudyIdBySerieId: (serieId: string) => {
    const filteredMatchedSeriesStudies = get().matchSeriesStudies.filter(
      (matchSerieStudy) => matchSerieStudy.serieId === serieId,
    );

    if (!filteredMatchedSeriesStudies.length) return;

    return filteredMatchedSeriesStudies[0].studyId;
  },
  addMatchSeriesStudies: (matchSeriesStudies: Array<MatchSerieStudy>) => {
    return set((state) => {
      state.matchSeriesStudies.push(...matchSeriesStudies);
      return state;
    });
  },
  removeMatchSerieStudy: (serieId: string) => {
    return set((state) => {
      const filteredStudies = state.matchSeriesStudies.filter(
        (currentMatchSerieStudy) => currentMatchSerieStudy.serieId !== serieId,
      );
      state.matchSeriesStudies = filteredStudies;
      return state;
    });
  },
});

export const useMatchSeriesStudiesStore = create<MatchSeriesStudiesStore>(immer(matchSeriesStudiesStore));

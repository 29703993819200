import { useEffect } from 'react';
import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import { shallow } from 'zustand/shallow';

import referenceLinesSyncronizer from '~/utils/cornestone/referenceLinesSyncronizer';
import { CORNERSTONE_REFERENCE_LINES_EVENT, ToolbarOption, SYNC_EVENT_KEY } from '~/constants';
import { useViewportStore, useToolbarStore } from '~/src/store';
import { useCornerstoneSynchToolWithContext } from '~/hooks';
import { useSyncActionBrowserWindowsCommunication } from '~/hooks';
import { getLaterality } from '~/components/Viewer/CornerstoneViewer/getOverlayData';
import { createMammographyReferencePoint } from '~/utils/cornestone/createMammographyReferencePoint';
import { ToolName } from '../constants/toolName';

export const useSyncMammographyReferenceLines = () => {
  const { notifyEvent } = useSyncActionBrowserWindowsCommunication();
  const [activeIndex, viewportData] = useViewportStore((state) => [state.activeIndex, state.viewportData], shallow);
  const toolbarOptionSelected = useToolbarStore((state) => state.toolbarOptionSelected);

  const [
    activeMammographyReferenceLinesSync,
    disableMammographyReferenceLinesSync,
  ] = useCornerstoneSynchToolWithContext({
    eventToListen: CORNERSTONE_REFERENCE_LINES_EVENT,
    synchronizerFunction: referenceLinesSyncronizer,
    toolName: ToolName.MammographyReferenceLines,
  });

  useEffect(() => {
    if (toolbarOptionSelected === ToolbarOption.WWWC) return;
    if (toolbarOptionSelected === ToolbarOption.REFERENCE_LINES) {
      activeMammographyReferenceLinesSync(activeIndex, true);
      return;
    }
    disableMammographyReferenceLinesSync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toolbarOptionSelected, activeIndex]);

  const lastImageIdLoaded = viewportData[activeIndex]?.lastImageIdLoaded;
  const syncMammographyReferenceLines = (element) => {
    if (toolbarOptionSelected !== ToolbarOption.REFERENCE_LINES || !lastImageIdLoaded) return;
    activeMammographyReferenceLinesSync(activeIndex, true);
    const { currentPoints, image } = element.detail;
    const laterality = getLaterality(image.imageId);
    const messagePayload = {
      laterality,
      point: currentPoints.image,
    };
    cornerstone.triggerEvent(element.srcElement, CORNERSTONE_REFERENCE_LINES_EVENT);
    notifyEvent({ scope: SYNC_EVENT_KEY.SYNC_REFERENCE_LINES, data: JSON.stringify(messagePayload) });
  };
  const registerRemoteMammographyReferenceLine = (incomingReference) => {
    activeMammographyReferenceLinesSync(activeIndex, true);
    if (toolbarOptionSelected !== ToolbarOption.REFERENCE_LINES) return;
    cornerstone.getEnabledElements().forEach((enabledElement) => {
      if (!enabledElement.image) return;
      const formatedPoint = createMammographyReferencePoint(incomingReference);
      cornerstoneTools.clearToolState(enabledElement.element, ToolName.MammographyReferenceLines);
      cornerstoneTools.addToolState(enabledElement.element, ToolName.MammographyReferenceLines, formatedPoint);
      cornerstone.updateImage(enabledElement.element);
    });
  };

  return {
    syncMammographyReferenceLines,
    registerRemoteMammographyReferenceLine,
  };
};

import { useMemo } from 'react';
import { shallow } from 'zustand/shallow';
import { StudyWorklistType } from '@eva-pacs/client';
import { studyDTO } from '~/src/dtos/study/studyDTO';
import { useStudyListStore } from '~/src/store';
import { Study } from '~/src/types/Study';
import { AddStudyPosition, useStudyActions } from './useStudyActions';
import { PAGE_SIZE, PAGE_SIZE_INFINITE_SCROLL } from '~/constants';

interface InsertNewStudyProps {
  nextId?: string;
  prevId?: string;
  newStudy: StudyWorklistType;
  enableNewStudyAnimation?: boolean;
  isSwapStudy?: boolean;
}

/**
 * Hook that takes some params to insert a new study based on the "two pointers" algorithm.
 * It looks for those 2 pointers in our store. If 2 pointers are found, then we insert this new study
 * in the middle of prev and next.
 * @author Salvador Gonzalez<salvador.gonzalez@edenmed.com>
 * Created at 2024-04-07
 */
export const useProcessStudyForInsertion = () => {
  const [getStudies, softResetStudies, getCurrentPage, enabledInfiniteScroll] = useStudyListStore(
    (state) => [state.getStudies, state.softResetAndAddNewStudies, state.getCurrentPage, state.enabledInfiniteScroll],
    shallow,
  );

  const pageSize = useMemo(() => {
    if (enabledInfiniteScroll) return PAGE_SIZE_INFINITE_SCROLL;

    return PAGE_SIZE;
  }, [enabledInfiniteScroll]);

  const { performAddStudy } = useStudyActions();

  const insertNewStudy = ({
    nextId,
    prevId,
    newStudy,
    enableNewStudyAnimation = true,
    isSwapStudy = false,
  }: InsertNewStudyProps) => {
    const studies = getStudies();
    const prevStudyFromStore = studies[nextId || ''];
    const nextStudyFromStore = studies[prevId || ''];
    const totalStudiesInStore = Object.entries(studies).length;

    // If we have both next and prev in the store, we need to add the study.
    // If we don't have both next and prev in the store, ignore it unless it is the first study..
    if (nextId && prevId && prevStudyFromStore && nextStudyFromStore) {
      const newNormalStudy = studyDTO(newStudy);

      return handleInsertStudyInMiddle(prevId, nextId, newNormalStudy, enableNewStudyAnimation, isSwapStudy);
    }

    const newAnimatedStudy = studyDTO(newStudy, enableNewStudyAnimation, isSwapStudy);

    // If there is no nextStudyId but we do have a prevId, it means it is the last study.
    if (!nextId && prevId) {
      // Infinite scroll case
      if (enabledInfiniteScroll) return performAddStudy(newAnimatedStudy);

      // if first page only and we have less than pageSize items in the store
      // we can add it to the last poisition always

      // TODO: determine if we need to validate first page here for pagination.

      // Pagination case
      if (totalStudiesInStore < pageSize) {
        return performAddStudy(newAnimatedStudy);
      }

      return;
    }

    // This means is the first study, so add it to the first position in the store for both cases
    // (infinite scroll/pagination)
    if (!prevId && nextId) {
      const currentPage = getCurrentPage();
      // Pagination Case
      // If the current page is 1, we know is the very first study, so proceed to add it as the first item in the store. If not, we don't know in which page
      // the study is.
      if (!enabledInfiniteScroll) {
        if (currentPage === 1) {
          return performAddStudy(newAnimatedStudy, AddStudyPosition.TOP);
        }

        return;
      }

      // Infinite scroll case (just add the study to the very first position here)
      return performAddStudy(newAnimatedStudy, AddStudyPosition.TOP);
    }

    // if neither of the ids are present, it could be the very fist study, or it could not be fetched from the db yet.
    // For both pagination/infinite scroll we need to check the size of the studies, if we are empty in the store, then proceed
    // adding the study. If not, ignore it.
    if (!nextId && !prevId && totalStudiesInStore === 0) return performAddStudy(newAnimatedStudy);
  };

  const handleInsertStudyInMiddle = (
    prevId: string,
    nextId: string,
    newStudy: Study,
    enableEntranceAnimation = true,
    isSwapStudy = false,
  ) => {
    const studies = getStudies();
    const array = Object.entries(studies);
    const tempArray: Array<Study> = array.map(([_, study]) => study);
    const prevIndex = tempArray.findIndex((item) => item.id === prevId);
    const nextIndex = tempArray.findIndex((item) => item.id === nextId);

    const formattedNewStudyWithAnimation = {
      ...newStudy,
      enableEntranceAnimation,
      isSwapStudy,
    };

    // returns the smallest between prevIndex + 1 and nextIndex (e.g: (2 + 1 = 3, 4 = 3))
    const newIndex = Math.min(prevIndex + 1, nextIndex);

    // If we have full the current page, remove the last element so a new study can
    // be inserted.
    if (tempArray.length >= pageSize) {
      tempArray.pop();
    }

    // Inserts new study without removing
    tempArray.splice(newIndex, 0, {
      ...formattedNewStudyWithAnimation,
      enableEntranceAnimation,
      isSwapStudy,
    });

    softResetStudies(tempArray);
  };

  return {
    handleInsertStudyInMiddle,
    insertNewStudy,
  };
};

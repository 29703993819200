import { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import {
  CANVAS_DEFAULT_VALUES,
  CANVAS_ID,
  CANVAS_INITIAL_SCALE,
  ECG_DISPLAY_OPTIONS,
  initializeECGCanvas,
  simpleChannelType,
} from '@eva-pacs/ecg';
import { FileType } from '@eva-pacs/core';

import { ECG_STATE_ENUM, useEcgStore, useViewportStore } from '~/src/store';
import { useErrorHandler } from '~/utils/appHelpers';

export const useEcgService = ({ viewportIndex }: { viewportIndex?: number }) => {
  const [setNewECGData, setAmplitude, setTime, setZoom, setCanvasReference, setXAxis, setYAxis, ecgData] = useEcgStore(
    (store) => [
      store.setNewECGData,
      store.setAmplitude,
      store.setTime,
      store.setZoom,
      store.setCanvasReference,
      store.setXAxis,
      store.setYAxis,
      store.ecgData,
    ],
    shallow,
  );
  const { viewportData, activeIndex } = useViewportStore();
  const { handleError } = useErrorHandler();

  const currentIndex = viewportIndex || activeIndex;
  const currentEcgViewportData = ecgData[currentIndex] || {};
  const { canvasReference } = currentEcgViewportData;

  const isCurrentStudyECG = viewportData[currentIndex].displayType === FileType.ECG;

  const fileToBuffer = (fileData: Blob, targetIndex: number, channelsData: Array<simpleChannelType>) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(fileData);
    reader.onload = function () {
      const resultBuffer = reader.result;
      const isNotAValidFile = !resultBuffer || typeof resultBuffer === 'string';
      if (isNotAValidFile) return;

      const ecgData = {
        viewerState: ECG_STATE_ENUM.COMPLETE,
        amplitude: CANVAS_DEFAULT_VALUES.AMPLITUDE,
        time: CANVAS_DEFAULT_VALUES.TIME,
        zoom: CANVAS_INITIAL_SCALE,
        xAxis: 0,
        yAxis: 0,
        canvasReference: null,
      };
      setNewECGData(ecgData, targetIndex);

      initializeECGCanvas({
        idView: `${CANVAS_ID}`,
        dataDICOMarrayBuffer: resultBuffer as ArrayBuffer,
        nameView: targetIndex.toString(),
        display: ECG_DISPLAY_OPTIONS['4x2.5r'],
        channelsData,
      })
        .then((result) => {
          if (!result.success || !result.canvasReference) return handleError({ logMessage: result.message });
          setCanvasReference(result.canvasReference, targetIndex);
        })
        .catch((result) => handleError({ logMessage: result.message }));
    };
  };

  const updateEcgStats = useCallback(() => {
    if (!canvasReference) return null;
    const { configuration, scale, canvasPan } = canvasReference;

    setAmplitude(configuration.AMPLITUDE, currentIndex);
    setTime(configuration.TIME, currentIndex);
    setZoom(scale, currentIndex);
    setYAxis(canvasPan.offset.y, currentIndex);
    setXAxis(canvasPan.offset.x, currentIndex);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasReference]);

  return { fileToBuffer, updateEcgStats, isCurrentStudyECG, currentEcgViewportData };
};

import { create, SetState } from 'zustand';
import produce from 'immer';

import { ScreenPreferences } from '~/utils/screenPreferences';

export const immer = (config) => (set, get) => config((fn) => set(produce(fn)), get);

/**
 * Screen Preferences Store
 */
export interface ScreenPreferencesStore {
  screenPreferenceList: Array<ScreenPreferences> | undefined;
  setScreenPreferenceList: (preferences: Array<ScreenPreferences>) => void;
}

const screenPreferencesStore = (set: SetState<ScreenPreferencesStore>): ScreenPreferencesStore => ({
  screenPreferenceList: undefined,

  setScreenPreferenceList: (preferences: Array<ScreenPreferences>) =>
    set((state) => {
      state.screenPreferenceList = preferences;
      return state;
    }),
});

export const useScreenPreferencesStore = create<ScreenPreferencesStore>(immer(screenPreferencesStore));

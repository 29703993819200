import React, { useMemo, useState } from 'react';
import cn from 'classnames';
import { shallow } from 'zustand/shallow';
import { useLocation } from 'react-router-dom';
import {
  Navbar as NavbarEden,
  CompanyAndProductLogo,
  ProductLogoType,
  AccountOption,
  ProfileButtonVariant,
} from '@evacenter/eden';

import { EVA_PRODUCT } from '~/constants';
import { useSessionStore } from '~/src/store/sessionStore';
import { ProductPopupMenu } from './ProductPopupMenu/ProductPopupMenu';

import { AccountMenu } from '../AccountMenu/AccountMenu';
import { withNotifications } from '../AccountMenu/withNotifications';
import { CAN_USE_NOTIFICATIONS, POLICIES, UserRole } from '~/constants';
import { getUserRole } from '~/utils/appHelpers';
import { useStudyListStore } from '~/src/store';
import { useFeatureFlagsStore } from '~/src/store/flagsStore';
import { FeatureFlags } from '~/src/constants/featureFlags';
import { useStudyListTabs } from '~/src/hooks/study/useStudyListTabs';
import { WorklistTabNames } from '~/src/types/StudyList';

const AccountMenuWithNotifications = withNotifications(AccountMenu);

export interface NavbarProps {
  /**
   * Descriptive text to be read to screenreaders
   */
  ariaLabel?: string;
  /**
   * Specify an optional className to be added to the component.
   */
  className?: string;
  /**
   * Current path of the router, used to handle rendering buttons
   */
  currentPath?: string;
  /**
   * If admin tab should be rendered
   */
  showAdminTab?: boolean;
  /**
   * If study list tab should be rendered
   */
  showStudyListTab?: boolean;
}

/**
 * Navbar is the building block for creating the global navigation bar for your experience.
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-07-16
 */
export const Navbar: React.FC<NavbarProps> = ({ className }) => {
  const location = useLocation();
  const [isHovering, setIsHovering] = useState(false);
  const [, currentProductPath] = location.pathname.split('/');
  const { currentOrganizationId, accounts, setCurrentOrganizationId } = useSessionStore();
  const [user] = useSessionStore((store) => [store.user, store.getCurrentAccount], shallow);
  const hasFlag = useFeatureFlagsStore((state) => state.hasFlag);
  const worklistTotalCount = useStudyListStore((state) => state.worklistTotalCount);
  const { activeTab } = useStudyListTabs();
  const isEmptyWorklist = worklistTotalCount === 0;
  const isWorklistEnabled = hasFlag(FeatureFlags.EnableNewWorklistSection);
  const shouldEnableWorklistBehavior = isEmptyWorklist && activeTab === WorklistTabNames.WORKLIST && isWorklistEnabled;
  const classes = cn(className, 'e-w-full e-justify-between e-z-10 e-unse', {
    'e-bg-transparent hover:e-bg-neutral-900 transition-colors duration-500 e-border-b-neutral-50/25': shouldEnableWorklistBehavior,
  });

  const iconClassName = cn('e-text-primary-300 transition-colors duration-500', {
    'text-basic-white': shouldEnableWorklistBehavior && !isHovering,
  });

  const currentRole = getUserRole(user);
  const isReferringPhysicianRole = currentRole === UserRole.REFERRING_PHYSICIAN;
  const physicianCanSeeNotifications = isReferringPhysicianRole && !user?.isMo;
  const canSeeNotifications = POLICIES[CAN_USE_NOTIFICATIONS](user);

  const accountOptions: Array<AccountOption> = accounts.map((account) => ({
    userFullname: account.userInfo.fullName as string,
    id: account.organizationId,
    userEmail: user?.email || '',
    laboratoryName: account.organizationName,
    isActive: account.organizationId === currentOrganizationId,
  }));

  const handleChangeAccount = (targetAccount: AccountOption) => {
    if (targetAccount.id === currentOrganizationId) return;
    setCurrentOrganizationId(targetAccount.id);
    window.location.reload();
  };

  const getProfileButtonVariant = () => {
    if (shouldEnableWorklistBehavior && !isHovering) return ProfileButtonVariant.white;

    return ProfileButtonVariant.default;
  };

  const currentProduct = useMemo(() => {
    if (currentProductPath === 'admin' || currentProductPath === 'admininvite') return EVA_PRODUCT.PANEL;
    return EVA_PRODUCT.PACS;
  }, [currentProductPath]);

  const renderAccountMenu = () => {
    if (canSeeNotifications && !physicianCanSeeNotifications)
      return (
        <AccountMenuWithNotifications
          profileButtonVariant={getProfileButtonVariant()}
          accounts={accountOptions}
          onOptionClick={handleChangeAccount}
        />
      );

    return (
      <AccountMenu
        profileButtonVariant={getProfileButtonVariant()}
        accounts={accountOptions}
        onOptionClick={handleChangeAccount}
      />
    );
  };

  const handleNavBarHover = () => {
    if (!shouldEnableWorklistBehavior) return;
    setIsHovering(true);
  };

  const handleOnFocusNavbar = () => {
    if (!shouldEnableWorklistBehavior) return;
  };

  const handleOnMouseOut = () => setIsHovering(false);

  const handleOnBlur = () => {
    if (!shouldEnableWorklistBehavior) return;
  };

  return (
    <div className="e-flex e-items-center e-relative e-z-10" style={{ backdropFilter: 'blur(8px)' }}>
      <NavbarEden className={classes}>
        <div
          onMouseOver={handleNavBarHover}
          onBlur={handleOnBlur}
          onMouseOut={handleOnMouseOut}
          onFocus={handleOnFocusNavbar}
          // style={{ borderBottom: '1px solid rgba(255, 255, 255, 0.22)' }}
          className="e-flex-wrap e-flex e-justify-between e-items-center e-w-full lg:e-flex-nowrap">
          {/* LEFT -  NAVBAR OPTIONS */}
          <div className="e-flex e-z-1 e-items-center e-h-10 e-space-x-4">
            <ProductPopupMenu iconClassName={iconClassName} selectedProductId={currentProduct} />
            <CompanyAndProductLogo product={ProductLogoType[currentProduct]} />
          </div>

          {/* RIGHT -  NAVBAR OPTIONS */}
          <div className="flex items-center ml-6 space-x-7 lg:ml-auto">{renderAccountMenu()}</div>
        </div>
      </NavbarEden>
    </div>
  );
};

import immer from 'immer';
import { create, SetState, GetState } from 'zustand';

import { ThumbnailItem } from '~/utils/thumbnails';

const mergeListsById = <T extends { id: string }>(list1: T[], list2: T[]): T[] => {
  const map = new Map<string, T>();

  // Agregar todos los elementos de list1 al mapa
  list1.forEach((item) => map.set(item.id, item));

  // Agregar/actualizar todos los elementos de list2 en el mapa
  list2.forEach((item) => map.set(item.id, item));

  // Convertir el mapa de vuelta a un array
  return Array.from(map.values());
};

interface ThumbnailSeriesStore {
  thumbailsList: Array<ThumbnailItem>;
  setThumbnailsList: (thumbailsList: Array<ThumbnailItem>) => void;
  getThumbnailsList: () => Array<ThumbnailItem>;
  setThumbnailData: (thumbnailSerieId: string, thumbailData: Partial<ThumbnailItem>) => void;
}

const thumbnailSeriesStore = (set: SetState<ThumbnailSeriesStore>, get: GetState<ThumbnailSeriesStore>) => ({
  thumbailsList: [],
  setThumbnailsList: (thumbailsList: Array<ThumbnailItem>) =>
    set(() => ({ thumbailsList: mergeListsById(get().thumbailsList, thumbailsList) })),
  getThumbnailsList: () => get().thumbailsList,
  setThumbnailData: (thumbnailSerieId: string, thumbailData: Partial<ThumbnailItem>) => {
    const thumbnailIndex = get().thumbailsList.findIndex((thumbnail) => thumbnail.id === thumbnailSerieId);
    if (thumbnailIndex === -1) return;
    set((state) => {
      const thumbnailList = [...get().thumbailsList];
      thumbnailList[thumbnailIndex] = { ...get().thumbailsList[thumbnailIndex], ...thumbailData };
      state.thumbailsList = thumbnailList;
      return state;
    });
  },
});

export const useThumbnailSeriesStore = create<ThumbnailSeriesStore>(immer(thumbnailSeriesStore));

import { create, SetState } from 'zustand';

import { DrawECGCanvasClass } from '@eva-pacs/ecg';

import { immer } from './flagsStore';

export enum ECG_STATE_ENUM {
  LOADING = 'LOADING',
  COMPLETE = 'COMPLETE',
}

export type EcgContent = {
  viewerState: ECG_STATE_ENUM;
  amplitude: number;
  time: number;
  zoom: number;
  xAxis: number;
  yAxis: number;
  canvasReference: DrawECGCanvasClass | null;
};

export type EcgData = {
  [key: number]: EcgContent;
};

/**
 * ECG Store
 */
export interface EcgStore {
  ecgData: EcgData;
  setTime: (time: number, viewportIndex: number) => void;
  setZoom: (zoom: number, viewportIndex: number) => void;
  setXAxis: (value: number, viewportIndex: number) => void;
  setYAxis: (value: number, viewportIndex: number) => void;
  setNewECGData: (data: EcgContent, viewportIndex: number) => void;
  setAmplitude: (amplitude: number, viewportIndex: number) => void;
  setViewerState: (state: ECG_STATE_ENUM, viewportIndex: number) => void;
  setCanvasReference: (canvasReference: DrawECGCanvasClass | null, viewportIndex: number) => void;
}

const ecgStore = (set: SetState<EcgStore>): EcgStore => ({
  ecgData: {},
  setNewECGData: (data: EcgContent, viewportIndex: number) => {
    return set((state) => {
      state.ecgData[viewportIndex] = data;
      return state;
    });
  },
  setAmplitude: (amplitude: number, viewportIndex: number) => {
    return set((state) => {
      state.ecgData[viewportIndex].amplitude = amplitude;
      return state;
    });
  },
  setTime: (time: number, viewportIndex: number) => {
    return set((state) => {
      state.ecgData[viewportIndex].time = time;
      return state;
    });
  },
  setZoom: (zoom: number, viewportIndex: number) => {
    return set((state) => {
      state.ecgData[viewportIndex].zoom = zoom;
      return state;
    });
  },
  setXAxis: (value: number, viewportIndex: number) => {
    return set((state) => {
      state.ecgData[viewportIndex].xAxis = value;
      return state;
    });
  },
  setYAxis: (value: number, viewportIndex: number) => {
    return set((state) => {
      state.ecgData[viewportIndex].yAxis = value;
      return state;
    });
  },
  setViewerState: (viewerState: ECG_STATE_ENUM, viewportIndex: number) => {
    return set((state) => {
      state.ecgData[viewportIndex].viewerState = viewerState;
      return state;
    });
  },
  setCanvasReference: (canvasReference: DrawECGCanvasClass | null, viewportIndex: number) => {
    return set((state) => {
      state.ecgData[viewportIndex].canvasReference = canvasReference;
      return state;
    });
  },
});

export const useEcgStore = create<EcgStore>(immer(ecgStore));

import { create, SetState } from 'zustand';
import { PRINTING_QUALITY, PRINTING_TEXT_OPTIONS } from '~/constants';
import { PrintParams } from '~/utils/print';
import { immer } from './flagsStore';

/**
 * Printer Store
 */
export interface PrinterStore {
  showPrintView: boolean;
  showText: PRINTING_TEXT_OPTIONS;
  printParams: PrintParams;
  quality: PRINTING_QUALITY;
  setShowPrintView: (showText: boolean) => void;
  setShowText: (showText: PRINTING_TEXT_OPTIONS) => void;
  setPrintParams: (printParams: PrintParams) => void;
  setQuality: (value: PRINTING_QUALITY) => void;
}

const printerStore = (set: SetState<PrinterStore>): PrinterStore => ({
  showPrintView: false,
  showText: 'left' as PRINTING_TEXT_OPTIONS, // If we put the enum value it occurs a hoisting error because zustand initializes itself before the enum is initialized
  printParams: {
    height: 11,
    width: 8.5,
    unit: 'in',
    orientation: 'portrait',
  },
  quality: 'high' as PRINTING_QUALITY,
  setShowPrintView: (showPrintView: boolean) => {
    return set((state) => {
      state.showPrintView = showPrintView;
      return state;
    });
  },
  setShowText: (showText: PRINTING_TEXT_OPTIONS) => {
    return set((state) => {
      state.showText = showText;
      return state;
    });
  },
  setPrintParams: (printParams: PrintParams) => {
    return set((state) => {
      state.printParams = printParams;
      return state;
    });
  },
  setQuality: (value: PRINTING_QUALITY) => {
    return set((state) => {
      state.quality = value;
      return state;
    });
  },
});

export const usePrinterStore = create<PrinterStore>(immer(printerStore));

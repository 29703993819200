import immer from 'immer';
import { create, SetState } from 'zustand';

export enum GlobalError {
  'GLOBAL_ERROR_TIMEOUT' = 'general.globalErrorTimeOut',
}

interface GlobalErrorStore {
  // The error message to display
  toastError?: GlobalError;

  // Sets the error message to display
  setToastError: (error: GlobalError) => void;
}

const globalErrorStore = (set: SetState<GlobalErrorStore>) => ({
  setToastError: (error: GlobalError) => set(() => ({ toastError: error })),
});

export const useGlobalErrorStore = create<GlobalErrorStore>(immer(globalErrorStore));

import { create, GetState, SetState } from 'zustand';
import produce from 'immer';

export const immer = (config) => (set, get) => config((fn) => set(produce(fn)), get);

export interface ReportTemplate {
  id: string;
  name: string;
  practitionerID: string;
  /**
   * Flag to know the template was obtained from the initial load or was created after this.
   */
  firstLoad?: boolean;
}

/**
 * Report Templates store.
 * @author Mauricio Campos <mauricio.campos@evacenter.com>
 * Created at 2023-09-06
 */

export interface ReportTemplatesStore {
  // Array to store the report templates
  templates: ReportTemplate[];

  // Function to add new templates to the store
  addTemplates: (elements: Array<ReportTemplate>) => void;

  // Function to set the templates in the store (replace existing ones)
  setTemplates: (elements: Array<ReportTemplate>) => void;

  // Function to remove a template by its unique identifier
  removeTemplate: (id: string) => void;
}

const featureFlagsStore = (
  set: SetState<ReportTemplatesStore>,
  get: GetState<ReportTemplatesStore>,
): ReportTemplatesStore => ({
  templates: [],
  addTemplates: (elements: Array<ReportTemplate>) => {
    return set((state) => {
      state.templates = [...get().templates, ...elements];
      return state;
    });
  },
  setTemplates: (elements: Array<ReportTemplate>) => {
    return set((state) => {
      state.templates = elements;
      return state;
    });
  },
  removeTemplate: (id: string) => {
    const updatedElements = get().templates.filter((element) => element.id !== id);
    set((state) => {
      state.templates = updatedElements;
      return state;
    });
  },
});

export const useReportTemplatesStore = create<ReportTemplatesStore>(immer(featureFlagsStore));

import { useViewportStore } from '~/src/store';

export const CONTAINER_CLASS = 'magnifyContainer';
export const HINT_CLASS = 'hintContainer';
export const ZOOM_CLASS = 'zoomContainer';
export const MAGNIFY_CLASS = 'magnifyTool';

export const CONTAINER_SELECTOR = `div.${CONTAINER_CLASS}`;
export const MAGNIFY_SELECTOR = `canvas.${MAGNIFY_CLASS}`;
export const CORNERSTONE_SELECTOR = `canvas.cornerstone-canvas`;

// Atemporal functions
export const disableZoomIndicator = (element) => {
  const zoomIndicator = getZoomIndicator(element);
  const newMagnifyContainer = getMagnifyContainer(element);

  if (!zoomIndicator) return;
  newMagnifyContainer.removeChild(zoomIndicator);
};

export const destroyMagnificationCanvas = (element) => {
  useViewportStore.getState().activeHotkeys();
  const magnifyContainer = getMagnifyContainer(element);

  if (!magnifyContainer) return;
  element.removeChild(magnifyContainer);
};

export const getZoomIndicator = (element): HTMLSpanElement => element.querySelector(`.${ZOOM_CLASS}`);

export const getMagnifyCanvas = (element): HTMLCanvasElement => element.querySelector(MAGNIFY_SELECTOR);

export const getMagnifyContainer = (element): HTMLDivElement => element.querySelector(CONTAINER_SELECTOR);

import React, { useEffect, useMemo, useState } from 'react';
import { shallow } from 'zustand/shallow';
import { AccountOption } from '@evacenter/eden';

import {
  useDeleteReferringPractitionerNotificationsMutation,
  useGetUserNotificationsLazyQuery,
  useUpdateNotificationsSubscription,
  Subscription,
  UserNotificationType,
  UserType,
} from '@eva-pacs/client';

import { useSessionStore } from '~/src/store';
import { AccountPopupMenuProps } from './AccountMenu';

type TUserNotificationType = Pick<
  UserNotificationType,
  'hasHighStudies' | 'hasNormalStudies' | 'highStudies' | 'normalStudies' | 'organization'
>;

/**
 * Higher Order Component that powers a component with notifications.
 * Currently is tied to account notifications, but can be refactored to match other types in a future.
 * @author Salvador Gonzalez<salvador.gonzalez@evacenter.com>
 * Created at 2023-09-06
 */
export const withNotifications = (Component) => (props: AccountPopupMenuProps) => {
  // Checks if WebSocket query is enabled on environment to fetch subscriptions through it.
  const WS_IS_ACTIVE = process.env.REACT_APP_WS_IS_ACTIVE === 'true';
  const [currentUserNotifications, setCurrentUserNotifications] = useState<Array<TUserNotificationType | null>>([]);
  const [deleteReferringPractitionerNotifications] = useDeleteReferringPractitionerNotificationsMutation({
    onCompleted: () => {
      if (props.onOptionClick) props.onOptionClick(selectedAccount);
    },
  });
  const [selectedAccount, setSelectedAccount] = useState<any>();
  const [user, getCurrentAccount] = useSessionStore((store) => [store.user, store.getCurrentAccount], shallow);

  const currentOrganizationId = getCurrentAccount()?.organizationId;

  const [getNotifications] = useGetUserNotificationsLazyQuery({
    variables: {
      userId: user?.id,
    },
    fetchPolicy: 'network-only',
    onCompleted: (userNotifications) => {
      if (!userNotifications.userNotifications) return setCurrentUserNotifications([]);
      setCurrentUserNotifications(userNotifications.userNotifications as Array<TUserNotificationType>);
    },
  });

  useEffect(() => {
    getNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  useUpdateNotificationsSubscription({
    // If WebSocket is not active in the current environment variables, skip the subscription.
    skip: !WS_IS_ACTIVE,
    variables: {
      userId: user?.id,
    },
    onSubscriptionData: ({ subscriptionData }) => {
      const { updateUserNotifications } =
        subscriptionData?.data ?? ({} as Pick<Subscription, 'updateUserNotifications'>);
      setCurrentUserNotifications((prevState) => {
        const notificationsFiltered = prevState.filter(
          (notification) => notification?.organization?.id !== updateUserNotifications?.organizationId,
        );

        return [...notificationsFiltered, ...(updateUserNotifications?.notifications as Array<TUserNotificationType>)];
      });
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const formattedAccounts = useMemo(() => formatAccountOptions(props.accounts, currentUserNotifications, user), [
    props.accounts,
    currentUserNotifications,
  ]);

  const handleOptionClick = (account: any) => {
    setSelectedAccount(account);

    return deleteReferringPractitionerNotifications({
      variables: {
        organizationId: account.id,
        userId: user?.id,
      },
    });
  };

  // If true, turns the notification's badge on.
  const userHasNotifications = useMemo(() => {
    // If user is multi org, it should check all notifications on all organizations.
    if (user?.isMo) return currentUserNotifications.length > 0;

    // but if it is a single user, it should check only if it has notifications on that organization.
    const currentOrganizationNotifications = currentUserNotifications.find(
      (userNotification) => userNotification?.organization?.id === currentOrganizationId,
    );

    return currentOrganizationNotifications?.hasHighStudies || currentOrganizationNotifications?.hasNormalStudies;
  }, [currentUserNotifications, currentOrganizationId, user]);

  return (
    <Component
      {...props}
      accounts={formattedAccounts}
      hasNotifications={userHasNotifications}
      onOptionClick={handleOptionClick}
    />
  );
};

const formatAccountOptions = (
  accounts: Array<AccountOption>,
  userNotifications: Array<TUserNotificationType | null>,
  user: UserType | null,
): Array<AccountOption> => {
  const parsedAccounts = accounts.map((accountOption) => {
    const currentOrganizationNotifications = userNotifications.find(
      (userNotification) => userNotification?.organization?.id === accountOption.id,
    );

    return {
      id: accountOption.id,
      userFullname: accountOption.userFullname,
      laboratoryName: accountOption.laboratoryName,
      isActive: accountOption.isActive,
      userEmail: user?.email ?? '',
      newStudyCount: currentOrganizationNotifications
        ? currentOrganizationNotifications?.normalStudies + currentOrganizationNotifications?.highStudies
        : undefined,
      urgentStudyCount:
        currentOrganizationNotifications && currentOrganizationNotifications?.highStudies !== 0
          ? currentOrganizationNotifications?.highStudies
          : undefined,
    };
  });

  // Applying 3 types of sorting:
  // 1.- Sort by urgentStudyCount, then apply alphabetical sorting.
  // 2.- If not present, sort by newStudyCount, then apply alphabetical sorting.
  // 3.- Undefineds (non accounts with notifications) are sent to the end, then apply alphabetical sorting.
  return parsedAccounts?.sort((previousValue, currentValue) => {
    const previousUrgentStudyCount = previousValue.urgentStudyCount ?? 0;
    const currentUrgentStudyCount = currentValue.urgentStudyCount ?? 0;
    const previousNewStudyCount = previousValue.newStudyCount ?? 0;
    const currentNewStudyCount = currentValue.newStudyCount ?? 0;
    // Compare by urgentStudyCount
    if (previousUrgentStudyCount !== 0 && currentUrgentStudyCount !== 0) {
      if (previousUrgentStudyCount === currentUrgentStudyCount) {
        // If urgentStudyCount is the same, compare alphabetically by some property (e.g., name)
        return previousValue.laboratoryName.localeCompare(currentValue.laboratoryName);
      }
      return currentUrgentStudyCount - previousUrgentStudyCount;
    }

    if (previousUrgentStudyCount !== 0) {
      return -1; // a comes before b
    }

    if (currentUrgentStudyCount !== 0) {
      return 1; // b comes before a
    }

    // If both have the same urgentStudyCount or both are undefined, compare by newStudyCount
    if (previousNewStudyCount !== 0 && currentNewStudyCount !== 0) {
      if (previousNewStudyCount === currentNewStudyCount) {
        // If newStudyCount is the same, compare alphabetically by some property (e.g., laboratoryName)
        return previousValue.laboratoryName.localeCompare(currentValue.laboratoryName);
      }
      return currentNewStudyCount - previousNewStudyCount;
    }

    if (previousNewStudyCount !== 0) {
      return -1; // a comes before b
    }

    if (currentNewStudyCount !== 0) {
      return 1; // b comes before a
    }

    // If newStudyCount is the same, compare alphabetically by some property (e.g., laboratoryName)
    return previousValue.laboratoryName.localeCompare(currentValue.laboratoryName);
  });
};
